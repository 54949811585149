import { Stack, Typography } from "@mui/material";
import React from "react";

import FriendsSvg from "@/svg/FriendsSvg";
import LightbulbMomentSvg from "@/svg/LightbulbMomentSvg";
import type { WishlistCategory } from "@/types/wishlists";

type BlankSlateProps = {
  category: WishlistCategory;
};

const BlankSlate = ({ category }: BlankSlateProps) => {
  return (
    <Stack alignItems="center" spacing={4} mt={5}>
      {category === "my" ? (
        <LightbulbMomentSvg sx={{ width: "15rem" }} />
      ) : (
        <FriendsSvg sx={{ width: "20rem" }} />
      )}
      <Stack textAlign="center" spacing={1}>
        <Typography variant="h2">No ideas found</Typography>
        <Typography variant="subtitle1">
          {category === "my" ? "Add an idea to get started." : "Ask your friend to add some ideas."}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BlankSlate;
