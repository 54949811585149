import { Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";

const PageContent = ({ children }: PropsWithChildren) => {
  return (
    <Stack flexGrow={1} pt={4}>
      {children}
    </Stack>
  );
};

export default PageContent;
