import type { ThemeOptions } from "@mui/material";

const typography: ThemeOptions["typography"] = {
  fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
  h1: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    fontSize: "2.5rem",
    letterSpacing: "-0.01rem"
  },
  h2: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    fontSize: "2rem",
    letterSpacing: "-0.0075rem"
  },
  h3: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    fontSize: "1.7rem",
    letterSpacing: "-0.00625rem"
  },
  h4: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    fontSize: "1.4rem",
    letterSpacing: "-0.005rem"
  },
  h5: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    fontSize: "1.3rem",
    letterSpacing: "-0.0025rem"
  },
  h6: {
    fontFamily: '"Lora", "Helvetica", "Arial", sans-serif',
    fontSize: "1.2rem",
    letterSpacing: "0rem"
  }
};

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#4f46e5"
    },
    secondary: {
      main: "#818cf8"
    },
    error: {
      main: "#ef4444"
    },
    warning: {
      main: "#eab308"
    },
    info: {
      main: "#06b6d4"
    },
    success: {
      main: "#22c55e"
    },
    divider: "rgba(255,255,255,0.06)"
  },
  typography
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#818cf8"
    },
    secondary: {
      main: "#a5b4fc"
    },
    error: {
      main: "#ef4444"
    },
    warning: {
      main: "#eab308"
    },
    info: {
      main: "#06b6d4"
    },
    success: {
      main: "#22c55e"
    },
    background: {
      default: "#09090b"
    },
    divider: "rgba(255,255,255,0.06)"
  },
  typography
};
