// localStorage.getItem
type RememberMeEnabled = {
  loginEmail: string;
  rememberMe: true;
};
type RememberMeDisabled = {
  loginEmail: null;
  rememberMe: false;
};
type RememberMeReturn = RememberMeEnabled | RememberMeDisabled;

export const getRememberMe = (): RememberMeReturn => {
  const loginEmail = localStorage.getItem("loginEmail");

  return loginEmail !== null ? { loginEmail, rememberMe: true } : { loginEmail, rememberMe: false };
};

export const setRemmeberMe = (loginEmail: string) => {
  localStorage.setItem("loginEmail", loginEmail);
};

export const removeRememberMe = () => {
  localStorage.removeItem("loginEmail");
};
