import { AccountCircleOutlined as AccountCircleOutlinedIcon } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import useRequest from "@/hooks/useRequest";
import { getErrorMessages } from "@/hooks/useRestApi";
import type { UserRecord, UserRecordPartial } from "@/types/users";

type UserPasswordResetResource = UserRecord & {
  password: string;
  password_confirmation: string;
};

type AccountPasswordResetFormData = {
  password: UserRecordPartial["password"];
  password_confirmation: UserRecordPartial["password_confirmation"];
  sid: UserRecordPartial["sid"];
  new_user: string;
};

const AccountPasswordResetEdit = () => {
  const navigate = useNavigate();
  const request = useRequest();
  const [alert, setAlert] = useState();
  const [searchParams] = useSearchParams();
  const sid = searchParams.get("sid");
  const newUser = !!searchParams.get("new_user");
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<AccountPasswordResetFormData>({
    defaultValues: {
      password: "",
      password_confirmation: "",
      sid: sid || "",
      new_user: newUser ? "true" : "false"
    }
  });

  const onSubmit: SubmitHandler<AccountPasswordResetFormData> = (data) =>
    request
      .patch("/api/account/password_reset", { data })
      .then(() => {
        navigate("/sign_in");
        reset();
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          setAlert(error.response.data.error);
        } else {
          const messages = getErrorMessages<UserPasswordResetResource>(error);
          if (messages?.password) setError("password", { message: messages.password });
          if (messages?.password_confirmation)
            setError("password_confirmation", { message: messages.password_confirmation });
          reset(undefined, { keepValues: true, keepErrors: true });
        }
      });

  const verb = newUser ? "Create" : "Reset";

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleOutlinedIcon />
        </Avatar>
        <Typography variant="h1">{verb} password</Typography>
        {alert && <Alert severity="error">{alert}</Alert>}
        <Box component="form" sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Password"
            type="password"
            margin="normal"
            autoComplete="new-password"
            fullWidth
            required
            {...register("password")}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
          />
          <TextField
            label="Password Confirmation"
            type="password"
            margin="normal"
            autoComplete="confirm-password"
            fullWidth
            required
            {...register("password_confirmation")}
            error={!!errors.password_confirmation?.message}
            helperText={errors.password_confirmation?.message}
          />
          {isSubmitting && <LinearProgress />}
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting || alert}
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            {verb} password
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountPasswordResetEdit;
