import {
  Button,
  Grid2 as Grid,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import useAppContext from "@/hooks/useAppContext";
import useRequest from "@/hooks/useRequest";
import { getErrorMessages } from "@/hooks/useRestApi";
import useToggle from "@/hooks/useToggle";
import type { UserRecord, UserRecordPartial } from "@/types/users";
import AccountEmailForm from "../../components/AccountEmailForm";
import AccountPasswordForm from "../../components/AccountPasswordForm";
import PageHeader from "../../components/PageHeader";

type AccountFormData = {
  name: UserRecordPartial["name"];
  username: UserRecordPartial["username"];
};

const Account = () => {
  const { auth, updateCurrentUser } = useAppContext();
  const request = useRequest();
  const {
    open: emailModalOpen,
    handleClose: handleEmailModalClose,
    handleOpen: handleEmailModalOpen
  } = useToggle();
  const {
    open: passwordModaOpen,
    handleClose: handlePasswordModalClose,
    handleOpen: handlePasswordModalOpen
  } = useToggle();
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<AccountFormData>({
    defaultValues: {
      name: auth?.currentUser.name || "",
      username: auth?.currentUser.username || ""
    }
  });

  const onSubmit: SubmitHandler<AccountFormData> = (data) =>
    request
      .patch<UserRecord>("/api/account", { data })
      .then((response) => {
        updateCurrentUser(response.data);
        reset({ name: response.data.name, username: response.data.username });
      })
      .catch((error) => {
        const messages = getErrorMessages<UserRecord>(error);
        if (messages.name) setError("name", { message: messages.name });
        if (messages.username) setError("username", { message: messages.username });
        reset(undefined, { keepValues: true, keepErrors: true });
      });

  return (
    <Stack>
      <PageHeader title="My Account" />
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid size={12}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h3">Profile</Typography>
              <Stack
                component="form"
                sx={{ mt: 1, width: "100%" }}
                onSubmit={handleSubmit(onSubmit)}
                direction={{ xs: "column", md: "row" }}
                spacing={2}
              >
                <TextField
                  label="Name"
                  type="text"
                  margin="normal"
                  autoComplete="name"
                  required
                  fullWidth
                  {...register("name")}
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
                <TextField
                  label="Username"
                  type="text"
                  margin="normal"
                  autoComplete="off"
                  required
                  fullWidth
                  slotProps={{ input: { inputProps: { "data-1p-ignore": true } } }}
                  {...register("username")}
                  error={!!errors.username?.message}
                  helperText={errors.username?.message}
                />
                {isSubmitting && <LinearProgress />}
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={isSubmitting}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h3">Email</Typography>
              <Typography variant="body1">{auth?.currentUser.email}</Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={handleEmailModalOpen}
                  sx={{ width: { xs: "100%", md: "auto" } }}
                >
                  Change email
                </Button>
              </Stack>
            </Stack>
            {emailModalOpen && (
              <AccountEmailForm open={emailModalOpen} onClose={handleEmailModalClose} />
            )}
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h3">Password</Typography>
              <Typography variant="body1">************</Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={handlePasswordModalOpen}
                  sx={{ width: { xs: "100%", md: "auto" } }}
                >
                  Change password
                </Button>
              </Stack>
            </Stack>
            {passwordModaOpen && (
              <AccountPasswordForm open={passwordModaOpen} onClose={handlePasswordModalClose} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Account;
