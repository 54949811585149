import { usePurchaseIdeaMutation } from "@/hooks/useIdeas";
import { IdeaRecord } from "@/types/ideas";
import { WishlistCategory, WishlistRecord } from "@/types/wishlists";
import { Check as CheckIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";

type PurchaseButtonProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
  idea: IdeaRecord;
};

const PurchaseButton = ({ category, wishlistId, idea }: PurchaseButtonProps) => {
  const confirm = useConfirm();
  const purchaseIdea = usePurchaseIdeaMutation(category, wishlistId);

  const handlePurchaseIdea = () => {
    confirm({
      title: "Flag as purchased?",
      description: "This will flag the idea as purchased",
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        purchaseIdea.mutate({ ideaId: idea.id });
        // TODO: Handle error
      })
      .catch(() => {});
  };

  return (
    <Button onClick={handlePurchaseIdea} startIcon={<CheckIcon />}>
      I purchased this
    </Button>
  );
};

export default PurchaseButton;
