import { Add as AddIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

import useToggle from "@/hooks/useToggle";
import type { WishlistCategory } from "@/types/wishlists";

import WishlistForm from "@/components/WishlistForm";

type CreateActionProps = {
  category: WishlistCategory;
};

const CreateAction = ({ category }: CreateActionProps) => {
  const { open, handleOpen, handleClose } = useToggle();

  return (
    <>
      <Button size="large" startIcon={<AddIcon />} variant="contained" onClick={handleOpen}>
        Create Wishlist
      </Button>
      {open && <WishlistForm category={category} open={open} onClose={handleClose} />}
    </>
  );
};

export default CreateAction;
