import { Container, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "@/components/Navbar";
import ScrollTop from "@/components/ScrollTop";

const ContentRoot = () => {
  return (
    <>
      <Navbar />
      <span id="back-to-top-anchor" />
      <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Stack flexGrow={1} pt={14}>
          <Outlet />
        </Stack>
      </Container>
      <ScrollTop />
    </>
  );
};

export default ContentRoot;
