import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";
import { Box, Button, Divider, Drawer, MenuItem } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import useAppContext from "@/hooks/useAppContext";
import useAuth from "@/hooks/useAuth";
import useToggle from "@/hooks/useToggle";

import type { NavbarLinkType } from "./Navbar";

type MobileNavbarProps = {
  links: NavbarLinkType[];
};

const MobileNavbar = ({ links }: MobileNavbarProps) => {
  const { isLoggedIn, isMobile, auth } = useAppContext();
  const { open, handleOpen, handleClose } = useToggle();
  const { signOutRequest } = useAuth();

  const handleSignOut = () => {
    handleClose();
    if (typeof auth?.sessionId === "undefined") return;

    signOutRequest({ sessionId: auth.sessionId });
  };

  return (
    <Box sx={{ display: { sm: "", md: "none" } }}>
      <Button
        variant="text"
        color="primary"
        aria-label="menu"
        onClick={handleOpen}
        sx={{ minWidth: "30px", p: "4px" }}
      >
        <MenuIcon />
      </Button>
      <Drawer
        anchor="right"
        open={isMobile && open}
        onClose={handleClose}
        PaperProps={{ sx: { left: 0, marginLeft: "7rem" } }}
      >
        <Box
          sx={{
            minWidth: "60dvw",
            p: 2,
            backgroundColor: "background.paper",
            flexGrow: 1
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              flexGrow: 1
            }}
          >
            <Button
              variant="text"
              color="primary"
              aria-label="close-menu"
              onClick={handleClose}
              sx={{ minWidth: "30px", p: "4px" }}
            >
              <CloseIcon />
            </Button>
          </Box>
          {links.map(({ label, restricted = false, ...props }) => {
            return restricted && !isLoggedIn() ? null : (
              <MenuItem key={label} component={RouterLink} onClick={handleClose} {...props}>
                {label}
              </MenuItem>
            );
          })}
          <Divider />
          {isLoggedIn() && (
            <>
              <MenuItem>
                <Button
                  color="primary"
                  variant="outlined"
                  component={RouterLink}
                  to="/account"
                  onClick={handleClose}
                  sx={{ width: "100%" }}
                >
                  My account
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  color="primary"
                  variant="contained"
                  component="a"
                  onClick={handleSignOut}
                  sx={{ width: "100%" }}
                >
                  Sign out
                </Button>
              </MenuItem>
            </>
          )}
          {!isLoggedIn() && (
            <>
              {/* <MenuItem>
                <Button
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  to="/sign_up"
                  sx={{ width: "100%" }}
                >
                  Sign up
                </Button>
              </MenuItem> */}
              <MenuItem>
                <Button
                  color="primary"
                  variant="outlined"
                  component={RouterLink}
                  to="/sign_in"
                  sx={{ width: "100%" }}
                >
                  Sign in
                </Button>
              </MenuItem>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileNavbar;
