import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from "axios";

import useAppContext from "./useAppContext";

export type RequestConfig = Omit<AxiosRequestConfig, "method" | "url"> & {
  authToken?: string;
};

export type RequestResponse<Type = void> = AxiosResponse<Type>;

const makeRequest = <Type = void>(
  method: "get" | "post" | "patch" | "put" | "delete",
  url: string,
  { authToken, headers: specifiedHeaders, ...config }: RequestConfig = {}
) => {
  const defaultHeaders: HeadersInit = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Frame-Options": "SAMEORIGIN",
    "X-XSS-Protection": "1; mode=block",
    "X-Content-Type-Options": "nosniff"
  };
  if (typeof authToken !== "undefined") {
    defaultHeaders["Authorization"] = `Bearer ${authToken}`;
  }
  const headers = { ...defaultHeaders, ...specifiedHeaders };

  return axios.request<Type>({ url, method, headers, ...config });
};

const isApiUrl = (url: string) => url.startsWith("/api");

const useRequest = () => {
  const { auth, clearAuth } = useAppContext();
  const authToken = auth?.authToken;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401 && isApiUrl(error.config.url)) clearAuth();
      return Promise.reject(error);
    }
  );

  return {
    get: <Type = void>(url: string, config?: RequestConfig) => {
      return makeRequest<Type>("get", url, { authToken, ...config });
    },
    post: <Type = void>(url: string, config?: RequestConfig) => {
      return makeRequest<Type>("post", url, { authToken, ...config });
    },
    patch: <Type = void>(url: string, config?: RequestConfig) => {
      return makeRequest<Type>("patch", url, { authToken, ...config });
    },
    put: <Type = void>(url: string, config?: RequestConfig) => {
      return makeRequest<Type>("put", url, { authToken, ...config });
    },
    delete: <Type = void>(url: string, config?: RequestConfig) => {
      return makeRequest<Type>("delete", url, { authToken, ...config });
    }
  };
};

export const isRequestError = (error: unknown) => {
  return isAxiosError(error);
};

export default useRequest;
