import React from "react";

import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import Wishlist from "../Wishlist";

type SharedWishlistProps = {
  category: WishlistCategory;
  wishlist: WishlistRecord;
};

const SharedWishlist = ({ category, wishlist }: SharedWishlistProps) => {
  return <Wishlist category={category} wishlist={wishlist} />;
};

export default SharedWishlist;
