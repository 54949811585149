import { useShowWishlistsQuery } from "@/hooks/useWishlists";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import Loading from "@/components/Loading";
import PageNotFound from "@/components/PageNotFound";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import Wishlist from "../Wishlist";
import SharedWishlist from "./SharedWishlist";

export type WishlistProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
};

const Show = ({ category, wishlistId }: WishlistProps) => {
  const showQuery = useShowWishlistsQuery(category, wishlistId);
  const { data: wishlist, isLoading } = useQuery(showQuery);

  if (isLoading) {
    return <Loading />;
  } else if (wishlist && category === "my") {
    return <Wishlist category={category} wishlist={wishlist} />;
  } else if (wishlist && category === "shared") {
    return <SharedWishlist category={category} wishlist={wishlist} />;
  } else if (!wishlist) {
    return <PageNotFound title="Wishlist not found" titleVariant="h4" />;
  }
};

export default Show;
