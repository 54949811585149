import { useQuery } from "@tanstack/react-query";
import React from "react";

import { useIndexWishlistsQuery } from "@/hooks/useWishlists";
import WishlistList from "../components/WishlistList";

const MyWishlists = () => {
  const { data: wishlists } = useQuery(useIndexWishlistsQuery("my"));

  return <WishlistList category="my" wishlists={wishlists || []} />;
};

export default MyWishlists;
