import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

type LoadingProps = {
  message?: string;
};

const Loading = ({ message = "Loading..." }: LoadingProps) => {
  return (
    <Stack alignItems="center">
      <CircularProgress />
      <Typography variant="h5" sx={{ marginTop: 4 }}>
        {message}
      </Typography>
    </Stack>
  );
};

export default Loading;
