import { AccountCircleOutlined as AccountCircleOutlinedIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  LinearProgress,
  Link,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import useRequest, { isRequestError } from "@/hooks/useRequest";
import type { UserRecordPartial } from "@/types/users";

type AccountPasswordResetFormData = {
  email: UserRecordPartial["email"];
};

const AccountPasswordReset = () => {
  const request = useRequest();
  const [requested, setRequested] = useState(false);
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<AccountPasswordResetFormData>({
    defaultValues: {
      email: ""
    }
  });

  const onSubmit: SubmitHandler<AccountPasswordResetFormData> = (data) =>
    request
      .post("/api/account/password_reset", { data })
      .then(() => {
        setRequested(true);
        reset();
      })
      .catch((error) => {
        if (isRequestError(error)) {
          setError("email", { message: error?.response?.data?.error || "Unable to login" });
          reset(undefined, { keepValues: true, keepErrors: true });
        } else {
          throw error;
        }
      });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleOutlinedIcon />
        </Avatar>
        {requested && (
          <Typography mt={4}>
            Password request submitted. Check your email for password reset instructions.
          </Typography>
        )}
        {!requested && (
          <>
            <Typography variant="h1">Reset password</Typography>
            <Box component="form" sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Email"
                type="email"
                margin="normal"
                autoComplete="username"
                fullWidth
                required
                autoFocus
                {...register("email")}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
              <Link component={RouterLink} to="/sign_in" sx={{ display: "block", mt: 1 }}>
                Back to sign in
              </Link>
              {isSubmitting && <LinearProgress />}
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting || requested}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                Send password reset instructions
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default AccountPasswordReset;
