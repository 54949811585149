import { Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import type { WishlistCategory } from "@/types/wishlists";

type TabsProps = {
  category: WishlistCategory;
};

const Tabs = ({ category }: TabsProps) => {
  return (
    <MuiTabs value={category} sx={{ mb: 3 }}>
      <MuiTab label="My Wishlists" value="my" component={RouterLink} to="/my_wishlists" />
      <MuiTab
        label="Wishlists Shared With Me"
        value="shared"
        component={RouterLink}
        to="/shared_wishlists"
      />
    </MuiTabs>
  );
};

export default Tabs;
