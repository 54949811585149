import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import DraggableIdealList from "@/components/DraggableIdeaList";
import IdeaForm from "@/components/IdeaForm";
import IdeaList from "@/components/IdeaList";
import PageContent from "@/components/PageContent";
import PageHeader from "@/components/PageHeader";
import { useIndexIdeasQuery } from "@/hooks/useIdeas";
import useToggle from "@/hooks/useToggle";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import ManageDropdown from "./ManageDropdown";

export type WishlistProps = {
  category: WishlistCategory;
  wishlist: WishlistRecord;
};

const Wishlist = ({ category, wishlist }: WishlistProps) => {
  const {
    open: ideaModalOpen,
    handleOpen: handleIdeaModalOpen,
    handleClose: handleIdeaModalClose
  } = useToggle();
  const { data: ideas } = useQuery(useIndexIdeasQuery(category, wishlist.id));
  const IdeaListComponent = category === "my" ? DraggableIdealList : IdeaList;

  return (
    <>
      <PageHeader title={wishlist.name} subtitle={wishlist.description}>
        {category === "my" && <ManageDropdown category={category} wishlist={wishlist} />}
        {category === "my" && (
          <>
            <Button variant="contained" onClick={handleIdeaModalOpen} sx={{ whiteSpace: "nowrap" }}>
              Add Idea
            </Button>
          </>
        )}
      </PageHeader>
      <PageContent>
        <IdeaListComponent category={category} wishlistId={wishlist.id} ideas={ideas || []} />
      </PageContent>
      {category === "my" && ideaModalOpen && (
        <IdeaForm
          category={category}
          wishlistId={wishlist.id}
          open={ideaModalOpen}
          onClose={handleIdeaModalClose}
        />
      )}
    </>
  );
};

export default Wishlist;
