import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  FormControlLabel,
  LinearProgress,
  Link,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import useAuth from "@/hooks/useAuth";
import { getRememberMe, removeRememberMe, setRemmeberMe } from "@/lib/rememberMe";

type SignInFormData = {
  email: string;
  password: string;
  remember_me: boolean;
};

const SignIn = () => {
  const { loginEmail, rememberMe } = getRememberMe();
  const {
    register,
    formState: { isSubmitting },
    reset,
    handleSubmit,
    control
  } = useForm<SignInFormData>({
    defaultValues: {
      email: loginEmail || "",
      password: "",
      remember_me: rememberMe
    }
  });
  const { field: rememberMeField } = useController({ name: "remember_me", control });
  const [loginError, setLoginError] = useState<string>();
  const { signInRequest } = useAuth();

  const onSubmit: SubmitHandler<SignInFormData> = (data) =>
    signInRequest({
      email: data.email,
      password: data.password,
      onSuccess: () => {
        if (data.remember_me) {
          setRemmeberMe(data.email);
          reset({ email: data.email, password: "", remember_me: true });
        } else {
          removeRememberMe();
          reset({ email: "", password: "", remember_me: false });
        }
      },
      onError: (errorMessage) => {
        setLoginError(errorMessage);
        reset(undefined, { keepValues: true });
      }
    });

  return (
    <Container component="main" maxWidth="xs">
      <Stack alignItems="center" mt={8}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h1">Sign in</Typography>
        <Box component="form" sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Email"
            type="email"
            margin="normal"
            autoComplete="username"
            fullWidth
            required
            autoFocus
            {...register("email")}
          />
          <TextField
            label="Password"
            type="password"
            margin="normal"
            autoComplete="current-password"
            fullWidth
            required
            {...register("password")}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={rememberMeField.value}
                  onChange={rememberMeField.onChange}
                  onBlur={rememberMeField.onBlur}
                  name={rememberMeField.name}
                  inputRef={rememberMeField.ref}
                />
              }
              label="Remember me"
              sx={{ marginLeft: -1 }}
            />
            <Link component={RouterLink} to="/account/password_reset">
              Forgot password?
            </Link>
          </Stack>
          {isSubmitting && <LinearProgress />}
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Sign in
          </Button>
          {loginError && <Alert severity="error">{loginError}</Alert>}
        </Box>
      </Stack>
    </Container>
  );
};

export default SignIn;
