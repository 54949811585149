import { Typography } from "@mui/material";
import React from "react";

const BlankSlate = () => {
  return (
    <Typography variant="subtitle1">You are not a member of any tribes to share with</Typography>
  );
};

export default BlankSlate;
