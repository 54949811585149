import React from "react";

import { Box } from "@mui/material";
import PageNotFound from "../../components/PageNotFound";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <PageNotFound title="Page not found" linkText="Go back to the home page" linkTo="/" />
    </Box>
  );
};

export default NotFound;
