import { styled } from "@mui/material/styles";
import React from "react";

import type { SvgProps } from "@/types/utils";

const FriendsSvg = styled((props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 1142.23 781.11"
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1="380.12"
          x2="530.06"
          y1="690.08"
          y2="690.08"
          gradientTransform="matrix(-1 0 0 1 967 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity=".25" />
          <stop offset=".54" stopColor="gray" stopOpacity=".12" />
          <stop offset="1" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
      </defs>
      <path
        fill="#6c63ff"
        d="M1140.99 327.75c-6.95 28.15-42.63 42.26-62.62 63.26-37.67 39.58-23 97.38.9 143.67 8 15.5 17.08 31.91 13.69 48.53-4.13 20.33-26.08 35.19-49.13 43.07-42 14.35-92.3 10.93-130.91-8.92-33.36-17.15-57.26-45-91.19-61.42-56.79-27.39-129.07-17-190.74 1.83-43.62 13.35-91.32 30.72-134.67 16.78-30.5-9.82-51.59-33.72-63.08-59-5.54-12.23-9.57-25.47-20.16-35.16-6.3-5.77-14.52-9.86-22.93-13.3-76.88-31.5-174-14.63-248.63-49.57-50.42-23.59-82.71-68.09-97-114.5s-12.63-95.07-9-142.87c2.58-34 7.21-70.22 33.47-96.44 27.78-27.73 75.45-38 118.55-32.77S269.69 55 304.06 77.12c43 27.66 82.78 62.09 135.51 73.29 35.91 7.63 73.68 3.45 110.72 2 61.92-2.41 123.86 3 185.43 8.84 30 2.87 60.1 5.88 89.84 10.24q10 1.46 20 3.15c3.34.57 6.68 1.16 10 1.77 2.6.47 5.19 1 7.78 1.47q6.13 1.19 12.22 2.5c11.66 2.5 23.21 5.33 34.64 8.55 39.74 11.21 71.6 31.57 109.13 45.79 24.46 9.27 51.13 11.83 74.29 23.63 28.5 14.49 54.68 39.8 47.37 69.4Z"
        opacity=".1"
      />
      <circle cx="939.95" cy="375.52" r="102.23" fill="#6c63ff" />
      <circle cx="831.23" cy="347.59" r="102.23" fill="#6c63ff" />
      <circle cx="711.55" cy="348.59" r="102.23" fill="#6c63ff" />
      <circle cx="672.65" cy="375.52" r="102.23" fill="#6c63ff" />
      <circle cx="606.82" cy="384.5" r="102.23" fill="#6c63ff" />
      <circle cx="579.89" cy="303.71" r="102.23" fill="#6c63ff" />
      <circle cx="609.81" cy="209.95" r="102.23" fill="#6c63ff" />
      <circle cx="666.66" cy="209.95" r="102.23" fill="#6c63ff" />
      <circle cx="699.58" cy="168.06" r="102.23" fill="#6c63ff" />
      <circle cx="783.36" cy="213.94" r="102.23" fill="#6c63ff" />
      <circle cx="978.85" cy="303.71" r="102.23" fill="#6c63ff" />
      <circle cx="920" cy="252.84" r="102.23" fill="#6c63ff" />
      <circle cx="882.1" cy="201.97" r="102.23" fill="#6c63ff" />
      <circle cx="810.29" cy="125.17" r="102.23" fill="#6c63ff" />
      <circle cx="943.94" cy="209.95" r="102.23" fill="#6c63ff" />
      <g opacity=".1">
        <circle cx="939.95" cy="375.52" r="102.23" />
        <circle cx="831.23" cy="347.59" r="102.23" />
        <circle cx="711.55" cy="348.59" r="102.23" />
        <circle cx="672.65" cy="375.52" r="102.23" />
        <circle cx="606.82" cy="384.5" r="102.23" />
        <circle cx="579.89" cy="303.71" r="102.23" />
        <circle cx="609.81" cy="209.95" r="102.23" />
        <circle cx="666.66" cy="209.95" r="102.23" />
        <circle cx="699.58" cy="168.06" r="102.23" />
        <circle cx="783.36" cy="213.94" r="102.23" />
        <circle cx="978.85" cy="303.71" r="102.23" />
        <circle cx="920" cy="252.84" r="102.23" />
        <circle cx="882.1" cy="201.97" r="102.23" />
        <circle cx="810.29" cy="125.17" r="102.23" />
        <circle cx="943.94" cy="209.95" r="102.23" />
      </g>
      <path fill="#67647e" d="M889.46 554.67s62.5-52.53 31.58-127.33l-30.92-11.58-33.55 103.29Z" />
      <path
        d="m892.79 412.2 7.4 2.77-32.94 101.41 28.74 31.12c-2.38 2.37-3.86 3.61-3.86 3.61l-32.9-35.62Z"
        opacity=".1"
      />
      <ellipse cx="579.5" cy="687.94" fill="#6c63ff" opacity=".1" rx="556.5" ry="93.16" />
      <path
        fill="#6c63ff"
        d="M1007.84 543.65s-20.33 20.33-8.85 50.5-19.67 80.33-19.67 80.33l1-.16c41.75-7.37 62.5-55.12 39.28-90.6-8.68-13.17-15.77-28.65-11.76-40.07Z"
      />
      <path
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        d="M1007.84 543.65s-11.48 24.92 0 45.25 2 77.38-28.53 85.58"
      />
      <path
        fill="#6c63ff"
        d="M1044.05 625.88s-23.2-3.69-25.31 17.1-44.62 23.67-44.62 23.67l.8.53c35.09 23 68.63 14.67 63.57-15.91-1.87-11.37-1.72-22.79 5.56-25.39Z"
      />
      <path
        fill="#f5f5f5"
        d="M1044.05 625.88s-23.2-3.69-25.31 17.1-44.62 23.67-44.62 23.67l.8.53c35.09 23 68.63 14.67 63.57-15.91-1.87-11.37-1.72-22.79 5.56-25.39Z"
        opacity=".2"
      />
      <path
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        d="M1044.05 625.88s-18 4-16.53 20.33-26.74 36-53.39 20.44"
      />
      <path
        fill="#67647e"
        d="m607.2 445.26 8.12 32.5s57.37 7.48 95.62 2.78a49.72 49.72 0 0 0 19.07-6.88c3.23-1.91 8.33-2.74 15.3 2.48 11.94 9 70.54 81 61.05 155.7a25.82 25.82 0 0 1-22.17 22.24 77.86 77.86 0 0 0-32.67 12.36l-29 19.31 297.35-6.5L989 641.88s-21.12-17.87-37.37-19.5c-4.74-.47-8.65-4.82-11.81-10.73-18.51-34.65-40.65-67.23-64.65-98.34-17.48-22.66-32.15-46.54-30.78-61.54 3.25-35.75 0-94.24 0-94.24l-61.74-8.12s8.12 78-66.62 97.49-108.83-1.64-108.83-1.64Z"
      />
      <g opacity=".1">
        <path d="M657.57 459.89s34.12 21.12 108.87 1.62 66.62-97.49 66.62-97.49l11.75 1.53c-.22-5-.39-8-.39-8l-61.74-8.12s8.12 78-66.62 97.49-108.87-1.62-108.87-1.62l8.12 32.5s21.92 2.86 47.7 3.93ZM801.89 681.06a77.86 77.86 0 0 1 32.67-12.36 25.78 25.78 0 0 0 22.16-22.15c9.54-74.7-49.09-146.83-61-155.79-13-9.75-19.5 1.62-19.5 1.62a104.61 104.61 0 0 1-14 2.67c21.06 28 51.19 81.71 44.14 136.87a25.78 25.78 0 0 1-22.16 22.15 77.86 77.86 0 0 0-32.67 12.36l-29 19.31 74.76-1.63Z" />
      </g>
      <path
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M656.96 602.91s-6.21-21.08 1.16-36.6a33.3 33.3 0 0 0 2.69-20.89 56 56 0 0 0-3-9.81"
      />
      <path
        fill="#6c63ff"
        d="M662.45 525.8c0 2.58-4.68 10.08-4.68 10.08s-4.65-7.5-4.65-10.08a4.68 4.68 0 1 1 9.36 0ZM670.19 540.11c-1.4 2.17-9.4 5.92-9.4 5.92s.15-8.83 1.55-11a4.68 4.68 0 1 1 7.85 5.09ZM669.85 564.55c-2.31 1.16-11.1.36-11.1.36s4.58-7.55 6.89-8.71a4.68 4.68 0 0 1 4.21 8.36ZM665.43 583.02c-2.08 1.54-10.88 2.23-10.88 2.23s3.24-8.22 5.32-9.75a4.68 4.68 0 0 1 5.56 7.52ZM650.96 546.55c1.85 1.8 10.49 3.67 10.49 3.67s-2.11-8.58-4-10.38a4.68 4.68 0 0 0-6.52 6.71ZM646.06 568.17c2.31 1.16 11.1.36 11.1.36s-4.58-7.55-6.89-8.71a4.68 4.68 0 0 0-4.21 8.36ZM644.18 589.86c2.08 1.54 10.88 2.23 10.88 2.23s-3.24-8.22-5.32-9.75a4.68 4.68 0 0 0-5.56 7.52Z"
      />
      <path
        fill="#a8a8a8"
        d="M643.45 600.85s11.35-2.27 14.37-5.3 16.64-8.32 18.16-4.54 20.43 16.64 7.56 18.91-30.26 3-34 1.51-6.09-10.58-6.09-10.58Z"
      />
      <path
        d="M683.55 608.55c-12.86 2.27-30.26 3-34 1.51-2.88-1.15-4.88-6.69-5.67-9.26l-.38.08s2.27 9.08 6.05 10.59 21.18.76 34-1.51c3.71-.66 4.77-2.19 4.37-4.14-.32 1.24-1.61 2.22-4.37 2.73Z"
        opacity=".2"
      />
      <path
        fill="#a8a8a8"
        d="M831.62 672.01s34.43-6.89 43.62-16.07 50.5-25.25 55.09-13.77 62 50.5 23 57.39-91.82 9.18-103.3 4.59-18.41-32.14-18.41-32.14Z"
      />
      <path
        d="M953.29 695.29c-39 6.89-91.82 9.18-103.3 4.59-8.74-3.5-14.82-20.3-17.22-28.11l-1.15.24s6.89 27.55 18.36 32.14 64.28 2.3 103.3-4.59c11.27-2 14.49-6.66 13.27-12.56-.95 3.85-4.88 6.82-13.26 8.29Z"
        opacity=".2"
      />
      <path
        fill="#6c63ff"
        d="M1064.66 476.35a21.82 21.82 0 0 1-3.71 11.51 21.82 21.82 0 0 1-3.77-11.49c0-6.35 1.64-11.5 3.71-11.51s3.75 5.14 3.77 11.49ZM782.37 470.77a21.82 21.82 0 0 1-3.74 11.5 21.82 21.82 0 0 1-3.74-11.5c0-6.35 1.67-11.5 3.74-11.5s3.74 5.15 3.74 11.5ZM1090.03 584.09a21.82 21.82 0 0 1 3.74 11.5 21.82 21.82 0 0 1-9.79-7.1c-3.74-5.14-5.41-10.29-3.74-11.5s6.06 1.97 9.79 7.1ZM970.57 523.88a21.82 21.82 0 0 1-3.74 11.5 21.82 21.82 0 0 1-3.74-11.5c0-6.35 1.67-11.5 3.74-11.5s3.74 5.17 3.74 11.5ZM900.69 485.68a21.82 21.82 0 0 1-3.74 11.5 21.82 21.82 0 0 1-3.74-11.5c0-6.35 1.67-11.5 3.74-11.5s3.74 5.15 3.74 11.5ZM857.34 508.97a21.82 21.82 0 0 1 2.47 11.84 21.82 21.82 0 0 1-9-8.12c-3.16-5.51-4.26-10.81-2.47-11.84s5.85 2.61 9 8.12ZM824.95 614.61a21.82 21.82 0 0 1-10.95 5.14 21.82 21.82 0 0 1 5.83-10.59c4.63-4.34 9.54-6.64 10.95-5.14s-1.2 6.25-5.83 10.59Z"
      />
      <path
        d="M824.95 614.61a21.82 21.82 0 0 1-10.95 5.14 21.82 21.82 0 0 1 5.83-10.59c4.63-4.34 9.54-6.64 10.95-5.14s-1.2 6.25-5.83 10.59Z"
        opacity=".1"
      />
      <path
        fill="#6c63ff"
        d="M892.96 608.09a21.82 21.82 0 0 1-10.95 5.14 21.82 21.82 0 0 1 5.83-10.59c4.63-4.34 9.54-6.64 10.95-5.14s-1.2 6.24-5.83 10.59Z"
      />
      <path
        d="M892.96 608.09a21.82 21.82 0 0 1-10.95 5.14 21.82 21.82 0 0 1 5.83-10.59c4.63-4.34 9.54-6.64 10.95-5.14s-1.2 6.24-5.83 10.59Z"
        opacity=".1"
      />
      <path
        fill="#6c63ff"
        d="M906 677.03a21.82 21.82 0 0 1-10.95 5.14 21.82 21.82 0 0 1 5.84-10.62c4.63-4.34 9.54-6.64 10.95-5.14s-1.2 6.27-5.84 10.62Z"
      />
      <path
        d="M906 677.03a21.82 21.82 0 0 1-10.95 5.14 21.82 21.82 0 0 1 5.84-10.62c4.63-4.34 9.54-6.64 10.95-5.14s-1.2 6.27-5.84 10.62Z"
        opacity=".1"
      />
      <path
        fill="#6c63ff"
        d="M90.47 566.82c15.33 22.63 10.44 103.65 10.44 103.65s-77.05-25.51-92.38-48.13a49.49 49.49 0 0 1 81.95-55.51Z"
      />
      <path
        d="M90.47 566.82c15.33 22.63 10.44 103.65 10.44 103.65s-77.05-25.51-92.38-48.13a49.49 49.49 0 0 1 81.95-55.51Z"
        opacity=".1"
      />
      <path
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        d="M22.04 553.66s57.74 44.58 79.17 116.89"
      />
      <path
        fill="#6c63ff"
        d="M115.75 609.12s-41-7-13 60.91c0 0 28.49-10.86 30.71-37.88a21.56 21.56 0 0 0-17.15-22.92Z"
      />
      <path
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        d="M116.35 609.05s7.81 37.56-13.56 61"
      />
      <path
        fill="#a8a8a8"
        d="M1008.63 672.14s17.11-3.4 21.67-7.93 25.1-12.46 27.38-6.8 30.8 24.92 11.41 28.31-45.63 4.53-51.33 2.27-9.13-15.85-9.13-15.85Z"
      />
      <path
        d="M1069.12 683.63c-19.39 3.4-45.63 4.53-51.33 2.27-4.34-1.73-7.36-10-8.56-13.87l-.57.12s3.42 13.59 9.13 15.86 31.94 1.13 51.33-2.27c5.6-1 7.2-3.28 6.6-6.2-.51 1.89-2.46 3.36-6.6 4.09Z"
        opacity=".2"
      />
      <circle cx="939.95" cy="352.58" r="102.23" fill="#6c63ff" />
      <circle cx="831.23" cy="324.65" r="102.23" fill="#6c63ff" />
      <circle cx="711.55" cy="325.65" r="102.23" fill="#6c63ff" />
      <circle cx="672.65" cy="352.58" r="102.23" fill="#6c63ff" />
      <circle cx="606.82" cy="361.56" r="102.23" fill="#6c63ff" />
      <circle cx="579.89" cy="280.77" r="102.23" fill="#6c63ff" />
      <circle cx="609.81" cy="187.01" r="102.23" fill="#6c63ff" />
      <circle cx="666.66" cy="187.01" r="102.23" fill="#6c63ff" />
      <circle cx="699.58" cy="145.12" r="102.23" fill="#6c63ff" />
      <circle cx="783.36" cy="191" r="102.23" fill="#6c63ff" />
      <circle cx="978.85" cy="280.77" r="102.23" fill="#6c63ff" />
      <circle cx="920" cy="229.9" r="102.23" fill="#6c63ff" />
      <circle cx="882.1" cy="179.03" r="102.23" fill="#6c63ff" />
      <circle cx="810.29" cy="102.23" r="102.23" fill="#6c63ff" />
      <circle cx="943.94" cy="187.01" r="102.23" fill="#6c63ff" />
      <path
        d="M1041.73 203.02s-95.21-45.15-152.82 34.87M791.65 111.55s-105.32-3.18-126 93.22M660.73 320.71s-95.21-45.16-152.82 34.84"
        opacity=".1"
      />
      <path
        fill="#6c63ff"
        d="M244.04 575.55s5.49 7.18-2.54 18-14.65 20-12 26.76c0 0 12.11-20.14 22-20.42s3.38-12.23-7.46-24.34Z"
      />
      <path
        d="M244.04 575.55a8.9 8.9 0 0 1 1.12 2.25c9.62 11.3 14.75 21.85 5.5 22.12-8.61.25-18.95 15.65-21.42 19.54a8.35 8.35 0 0 0 .29.89s12.11-20.14 22-20.42 3.35-12.27-7.49-24.38Z"
        opacity=".1"
      />
      <path
        fill="#ffd037"
        d="M254.25 584.71c0 2.53-.28 4.58-.63 4.58s-.63-2-.63-4.58.35-1.34.7-1.34.56-1.19.56 1.34Z"
      />
      <path
        fill="#ffd037"
        d="M257.75 587.72c-2.22 1.21-4.16 1.94-4.32 1.63s1.5-1.54 3.72-2.75 1.34-.33 1.51 0 1.31-.05-.91 1.12Z"
      />
      <path
        fill="#6c63ff"
        d="M215.02 575.55s-5.49 7.18 2.54 18 14.65 20 12 26.76c0 0-12.11-20.14-22-20.42s-3.39-12.23 7.46-24.34Z"
      />
      <path
        d="M215.02 575.55a8.9 8.9 0 0 0-1.12 2.25c-9.62 11.3-14.75 21.85-5.5 22.12 8.61.25 18.95 15.65 21.42 19.54a8.35 8.35 0 0 1-.29.89s-12.11-20.14-22-20.42-3.36-12.27 7.49-24.38Z"
        opacity=".1"
      />
      <path
        fill="#ffd037"
        d="M204.81 584.71c0 2.53.28 4.58.63 4.58s.63-2 .63-4.58-.35-1.34-.7-1.34-.56-1.19-.56 1.34Z"
      />
      <path
        fill="#ffd037"
        d="M201.3 587.72c2.22 1.21 4.16 1.94 4.32 1.63s-1.5-1.54-3.72-2.75-1.34-.33-1.51 0-1.27-.05.91 1.12Z"
      />
      <path
        fill="#a8a8a8"
        d="M206.56 619.4s15.36-.47 20-3.77 23.63-7.23 24.77-1.95 23.08 26.29 5.74 26.43-40.29-2.7-44.91-5.52-5.6-15.19-5.6-15.19Z"
      />
      <path
        d="M257.37 638.28c-17.34.14-40.29-2.7-44.91-5.52-3.52-2.14-4.92-9.83-5.39-13.38h-.51s1 12.39 5.59 15.2 27.57 5.66 44.91 5.52c5 0 6.73-1.82 6.64-4.46-.69 1.61-2.58 2.61-6.33 2.64Z"
        opacity=".2"
      />
      <path
        fill="#6c63ff"
        d="M119.56 718.29a11.67 11.67 0 0 0 3.83-5.78c.5-2.3-.48-5.05-2.68-5.89-2.46-.94-5.09.76-7.08 2.49s-4.28 3.69-6.89 3.32a10.48 10.48 0 0 0 3.24-9.81 4.1 4.1 0 0 0-.9-2c-1.37-1.46-3.84-.83-5.48.32-5.2 3.66-6.65 10.72-6.68 17.08-.52-2.29-.08-4.68-.1-7s-.66-5-2.64-6.22a8 8 0 0 0-4-.95c-2.34-.09-4.94.15-6.54 1.86-2 2.12-1.47 5.69.26 8s4.35 3.8 6.77 5.42a15 15 0 0 1 4.84 4.61 4.57 4.57 0 0 1 .36.82h14.65a40.83 40.83 0 0 0 9.04-6.27ZM1029.56 732.29a11.67 11.67 0 0 0 3.83-5.78c.5-2.3-.48-5.05-2.68-5.89-2.46-.94-5.09.76-7.08 2.49s-4.28 3.69-6.89 3.32a10.48 10.48 0 0 0 3.24-9.81 4.1 4.1 0 0 0-.9-2c-1.37-1.46-3.84-.83-5.48.32-5.2 3.66-6.65 10.72-6.68 17.08-.52-2.29-.08-4.68-.1-7s-.66-5-2.64-6.22a8 8 0 0 0-4-.95c-2.34-.09-4.94.15-6.54 1.86-2 2.12-1.47 5.69.26 8s4.35 3.8 6.77 5.42a15 15 0 0 1 4.84 4.61 4.57 4.57 0 0 1 .36.82h14.65a40.83 40.83 0 0 0 9.04-6.27Z"
      />
      <path
        fill="#dc9ba3"
        d="M688.69 726.87s-1 15.66 1 18.26c0 0-45.92 8.35-51.66 5.74s-3.13-6.78 0-8.35 18.79-7.31 20.87-18.26c0 0-6.78-3.13 15.13 1s14.66 1.61 14.66 1.61Z"
      />
      <path
        d="M688.69 726.87s-1 15.66 1 18.26c0 0-45.92 8.35-51.66 5.74s-3.13-6.78 0-8.35 18.79-7.31 20.87-18.26c0 0-6.78-3.13 15.13 1s14.66 1.61 14.66 1.61Z"
        opacity=".05"
      />
      <path
        fill="#ffd2d9"
        d="M867.68 575.01s-5.74 9.39-2.09 27.66-9.39 18.26-9.39 18.26h-26.08l-8.87-23 12.52 1s8.35-2.09 4.7-21.4ZM789.41 553.1c4 12.71 9 28.19 11.67 36.44l1.9 5.83 17.22-17.74s-.76-1.76-1.89-4.22c-2.52-5.46-6.91-14.37-9.07-15.09 0 0 24-1.57 31.31 4.17s5.22-15.66 5.22-15.66-14.09-1-19.31-4.17-33.4-6.26-33.4-6.26-9.39-1.57-3.65 16.7ZM632.85 706.55l3.65 26.61 9.39-1s.57-.54 1.53-1.4c2.53-2.23 7.8-6.6 12.84-9a15.73 15.73 0 0 1 5.46-1.63l-10.44-15.66a57.66 57.66 0 0 1-7.79 2.31 37.61 37.61 0 0 1-8.39 1 15.32 15.32 0 0 1-6.25-1.23Z"
      />
      <path
        fill="#e9e9ef"
        d="M658.94 725.31c4.7 4.17 26.09-4.7 29.75 8.87l3.36-12.87 10.35-39.6 1.42-5.45 8.62-.37 98.93-4.22 5.6-.24 3-.13-3.92-1.94-26.09-12.93s-9.4-.66-22.36-2.44c-19.65-2.69-47.49-8-63.21-17.39-26.09-15.66-36 37.05-36 37.05s-4 13.37-7 26.55a153.76 153.76 0 0 0-3.15 17.25c-.45 4.03-.31 6.95.7 7.86Z"
      />
      <path
        d="M658.94 725.31c4.7 4.17 26.09-4.7 29.75 8.87l3.36-12.87 10.35-39.6 1.42-5.45 8.89-.55 95.58-5.88 5.9-.37 1.82-.11 2.35-.15-28.44-12.79s-9.4-.66-22.36-2.44c-19.65-2.69-47.49-8-63.21-17.39-26.09-15.66-36 37.05-36 37.05s-4 13.37-7 26.55a153.76 153.76 0 0 0-3.15 17.25c-.41 4.05-.27 6.97.74 7.88Z"
        opacity=".05"
      />
      <path
        d="m647.5 706.74 9.13 10.71 3.64 4.27a15.73 15.73 0 0 1 5.46-1.63l-10.44-15.66a57.66 57.66 0 0 1-7.79 2.31Z"
        opacity=".1"
      />
      <path
        fill="#e9e9ef"
        d="m647.99 705.48 10.21 12 4.92 5.77v1.57c6 1.39 24.79-2.57 28.93-3.48l.81-.18c12 .52 56.36-25.57 56.36-25.57s48.53 8.35 72 6.26c13.11-1.17 16.79-5.91 17.48-9.88a10.29 10.29 0 0 0-.78-5.78l-21-14.75-2.78-2-4.44-3.13s-7.72-1.75-17.52-3.91c-15.32-3.38-35.73-7.77-39.86-8.08-6.78-.52-24 5.22-33.92 16.7a29.31 29.31 0 0 1-5.72 4.67l-.27.18c-2.79 1.85-6.22 3.81-10 5.82-12.63 6.65-29.47 13.8-41.05 18.49-7.92 3.23-13.37 5.3-13.37 5.3Z"
      />
      <path
        d="m632.85 706.55 3.65 26.61 9.39-1s.57-.54 1.53-1.4c-2.08 0-5.51-.55-6.23-4.86 0 0 3.62-12.65-2.09-18.1a15.32 15.32 0 0 1-6.25-1.25Z"
        opacity=".1"
      />
      <path
        fill="#dc9ba3"
        d="M640.12 725.83s5.22-18.26-6.78-20.35l-19.83 7.83-23 1.57s-6 9.79 3.61 20.77a29.52 29.52 0 0 0 20.94 9.9l25.06 1.15 7.87-16.15s-6.79 1.54-7.87-4.72Z"
      />
      <path
        d="M767.57 653.99c2.63.77 13.51 4 24.65 8.43a137.31 137.31 0 0 1 16.06 7.41c1.08.61 2.11 1.22 3.08 1.83a26.56 26.56 0 0 1 6.73 5.64c6.44 8.33 15.9 12.82 20.61 14.65a10.29 10.29 0 0 0-.78-5.78l-21-14.75 3-.13-3.92-1.94 2.35-.15-28.44-12.79s-9.38-.64-22.34-2.42ZM801.12 589.55l1.9 5.83 17.22-17.74s-.76-1.76-1.89-4.22c-2.23 4.59-12.62 12.65-17.23 16.13Z"
        opacity=".1"
      />
      <path
        fill="#cedad5"
        d="M863.21 598.2a30.34 30.34 0 0 0 1.35-5.44l22.43 7.79s15.39 11.74 13.83 37.31c0 0-19-7-27.4 1.3l-.17 1.47a67.84 67.84 0 0 1-18.4 38.82c-4.15 4.36-8.06 9.27-10.13 14 0 0-14.61-4.17-23.48-15.66s-51.12-23.45-51.12-23.45 22.44.52 25.57-7.31 13.57-24.53 9.92-37.57-5.74-17.22-5.74-17.22 21.4-15.13 19.83-19.83l13.57 15.66 6.26 2.09a10 10 0 0 0-.74 3.82c-.36 14.17 19.66 17.57 24.42 4.22Z"
      />
      <path
        d="M839.25 584.48a54.1 54.1 0 0 0-.79-5.81l29.22-2.61s-2 1.75-2.9 8.27c-3.43 2-7.43 4.78-11.71 4.78a22.86 22.86 0 0 1-13.82-4.63Z"
        opacity=".1"
      />
      <circle cx="852.81" cy="565.36" r="22.96" fill="#ffd2d9" />
      <path
        fill="#844f6e"
        d="M869.77 571.36h-3.13v-8.87l-3.13-9.39s-15.66-7.31-23-5.74l-5.7 5.19s-1.57-12.52 7.83-17.22 26.09-2.61 30.27 1 11 9.92 5.74 27.14l-1.53 4.24s-6.31-2.09-7.35 3.65Z"
      />
      <g opacity=".05">
        <path d="m829.21 583.41-1.71-.57-7.88-9.09a2.22 2.22 0 0 0 .05-1.34ZM840.19 600.25a11.82 11.82 0 0 1-7.17-11.5v-.88l.16.19 6.26 2.09a10 10 0 0 0-.74 3.82 12.47 12.47 0 0 0 1.49 6.28ZM779.32 653.91c13.08 4.55 30.79 11.72 36.18 18.69l.4.5c-14.33-9.83-45.8-18.77-45.8-18.77a81.34 81.34 0 0 0 9.22-.42ZM838.98 688.26c2.07-4.71 6-9.63 10.13-14a67.84 67.84 0 0 0 18.4-38.82l.17-1.47c8.35-8.35 27.4-1.3 27.4-1.3 1-16.22-4.84-26.87-9.23-32.49l1.15.4s15.39 11.74 13.83 37.31c0 0-19-7-27.4 1.3l-.17 1.47a67.84 67.84 0 0 1-18.4 38.82c-4.15 4.36-8.06 9.27-10.13 14 0 0-12.72-3.64-21.62-13.44a54 54 0 0 0 15.87 8.22Z" />
      </g>
      <path
        fill="#ffd2d9"
        d="M844.72 627.2s25 36 38.09 46.44 16.7-4.7 16.7-4.7v-33.39l-23-2.61-.25 3.17-.28 3.62-21.4-23s-21.4-19.31-25.57-7.83 15.71 18.3 15.71 18.3Z"
      />
      <path
        d="M876.31 636.11a49.72 49.72 0 0 0 9.9 4.4c4.93 1.52 10.58-.08 13.31-1.06v-3.9l-23-2.61Z"
        opacity=".1"
      />
      <path
        fill="#cedad5"
        d="m899.78 632.16 1.34 5.59s-8.16 3.8-14.94 1.72a59.33 59.33 0 0 1-13-6.26c-1-.52 4.17-6.26 4.17-6.26Z"
      />
      <path d="M749.48 695.82a42.39 42.39 0 0 0-11.48-6.27 41 41 0 0 1-11-5.74" opacity=".1" />
      <path
        fill="#fff"
        d="m590.58 714.87.85-.06c-1.24 3.15-3 10.85 4.32 19.26a29.52 29.52 0 0 0 20.87 9.89l24.3 1.14-.78 1.6-25.08-1.15a29.52 29.52 0 0 1-20.87-9.89c-9.61-11-3.61-20.79-3.61-20.79Z"
        opacity=".2"
      />
      <path fill="#0f0" d="M679.19 737.24q-.11.47-.25.93c-.51-.29-1.24-.85.25-.93Z" opacity=".05" />
      <path
        fill="#fff"
        d="M638.12 749.31c5.35 2.43 45.57-4.65 51-5.63a4.06 4.06 0 0 0 .62 1.45s-45.92 8.35-51.66 5.74c-3-1.34-3.69-3.1-3.29-4.7.33 1.12 1.27 2.23 3.33 3.14Z"
        opacity=".2"
      />
      <path
        d="M838.22 554.39a35.56 35.56 0 0 1-13.24-14.2 3.2 3.2 0 0 1-.49-2.19c.37-1.42 2.22-1.67 3.56-2.28 2.49-1.14 3.54-4 5.17-6.22a12.45 12.45 0 0 1 12.17-4.67c2.38.48 4.58 1.66 7 2 5.18.76 10.82-2.38 15.47 0 2.12 1.09 3.56 3.14 4.82 5.16 3.17 5.07 7.14 10.46 4.26 16.38-1.65 3.4-4.29 6.22-8 7.21s-8 .16-11.78-.24c-3.1-.34-6.36-1-9.48-.72-3.68.38-5.97 1.87-9.46-.23Z"
        opacity=".1"
      />
      <path
        fill="#844f6e"
        d="M838.22 553.34a35.56 35.56 0 0 1-13.24-14.2 3.2 3.2 0 0 1-.49-2.19c.37-1.42 2.22-1.67 3.56-2.28 2.49-1.14 3.54-4 5.17-6.22a12.45 12.45 0 0 1 12.17-4.67c2.38.48 4.58 1.66 7 2 5.18.76 10.82-2.38 15.47 0 2.12 1.09 3.56 3.14 4.82 5.16 3.17 5.07 7.14 10.46 4.26 16.38-1.65 3.4-4.29 6.22-8 7.21s-8 .16-11.78-.24c-3.1-.34-6.36-1-9.48-.72-3.68.38-5.97 1.88-9.46-.23Z"
      />
      <path
        fill="url(#a)"
        d="M436.94 737.11c0 10.76.6 17.87.6 17.87s15.37.86 14.94 15.11 59.74 17.27 59.74 17.27v-3.66c5.72.85 11.84 1.54 15.27 1.21-.23 1.11-.5 2.28-.76 3.32-.28.28-5.82 5.88-5.64 11.84-.1 3 1.26 6.08 5.66 8.64C539.45 816.13 579 812 579 812c6-3.94 8-7.29 7.89-10.07.19-4.1-3.9-7-7.65-8.87a13.5 13.5 0 0 0-9.36-1 44.92 44.92 0 0 1-12.91.95 20 20 0 0 1 .67-2.54c4.06 1.15 6.86 2 6.86 2s21.08-11.22 12.3-29.35a14.85 14.85 0 0 0-6-5.93c6.77-11.67 13.2-25.41 12.56-34.65-1.07-15.42-31.69-12.06-43.16-10.25l-22.11-21.69c0-16 .49-34.37-1-38.35-2.64-6.91-1.76-6-3.51-9.06l-.07-.11c-1-1.62-3.72-4.19-4.42-3.84a44.62 44.62 0 0 1 .13-9.55A27.25 27.25 0 0 0 528 603.91a26.68 26.68 0 0 0-3-12.31l.3-.13-2.9-14.26a2.37 2.37 0 0 1 .13-1.85c.3-.4.81-.52 1.2-.8a2.76 2.76 0 0 0 .47-3.36 4.58 4.58 0 0 0-1.09-1.39c-2.44-2.77-7.63-1.78-10.82-2.19-4.32-.55-8.85-1.35-12.94.79a21.38 21.38 0 0 1-2.75 1.44 11.84 11.84 0 0 1-4 .38 40.4 40.4 0 0 0-7.26.65c-3.12-1.13-6.79-.13-9.68 1.6A21.35 21.35 0 0 0 465.5 592a41.36 41.36 0 0 0 1.39 7.15l5.35 20.85a13.59 13.59 0 0 0 2 4.88 5.75 5.75 0 0 0 .8.86 4.63 4.63 0 0 0 4.26 1.62 2.89 2.89 0 0 0 .59-.21 29.57 29.57 0 0 1-1.27 6.46c-6.12 1.78-15 8-25.66 26-13.68 23.06-15.96 57.06-16.02 77.5Zm87.3-14.64Z"
        transform="translate(-28.88 -59.45)"
      />
      <path fill="#5f5d7e" d="M521.09 707.65h-59.84v-42.86h59.84z" />
      <path
        fill="#5f5d7e"
        d="M506.24 655.45s44.56-9.34 45.83 9.34-26.31 56-26.31 56l-25-5.09 7.21-28.86s-28.43-6.37-30.13-6.79 28.4-24.6 28.4-24.6Z"
      />
      <path
        d="M506.24 655.45s44.56-9.34 45.83 9.34-26.31 56-26.31 56l-25-5.09 7.21-28.86s-28.43-6.37-30.13-6.79 28.4-24.6 28.4-24.6Z"
        opacity=".1"
      />
      <path
        fill="#5f5d7e"
        d="M529.59 725.9s-4.24 8.06-3 11.46-30.13-4.67-30.13-4.67 2.55-9.76 2.12-11 31.01 4.21 31.01 4.21Z"
      />
      <path
        fill="#fdc2cc"
        d="m502 670.73 3.12 2.82 2 1.82s10.19 16.55 23.34 6.37-10.21-17.8-14.88-18.19a5.86 5.86 0 0 1-4.4-3.19 14 14 0 0 1-1.54-4Z"
      />
      <path
        d="m502 670.73 3.12 2.82 2 1.82s10.19 16.55 23.34 6.37-10.21-17.8-14.88-18.19a5.86 5.86 0 0 1-4.4-3.19 14 14 0 0 1-1.54-4Z"
        opacity=".05"
      />
      <path
        d="M529.59 725.9s-4.24 8.06-3 11.46-30.13-4.67-30.13-4.67 2.55-9.76 2.12-11 31.01 4.21 31.01 4.21ZM502 670.73l3.12 2.82c0-5.44 3.61-10.44 6.08-13.25a14 14 0 0 1-1.54-4Z"
        opacity=".1"
      />
      <path fill="#c4c8e2" d="m483.33 627.87 29.28 29.28s-10.19 8.91-8.06 18.25l-21.22-23.77Z" />
      <path
        d="M483.54 627.87v23.77l3.4 3.81 10.09 11.3 7.73 8.66c-2.12-9.34 8.06-18.25 8.06-18.25Z"
        opacity=".1"
      />
      <path
        fill="#d39999"
        d="M483.33 585.85s0 8.91-7.64 6.37-1.7 100.58-1.7 100.58 22.07-16.55 17.82-28.43-5.09-20.82-4.69-32.68-3.79-45.84-3.79-45.84Z"
      />
      <path
        fill="#fdc2cc"
        d="M482.9 562.09s-6.37 17.4 0 30.56-37.78-7.22-37.78-7.22 9.76-4.67 6.37-27.16Z"
      />
      <path
        d="M486.72 641.45a125.19 125.19 0 0 0 0 14l10.09 11.3a85.06 85.06 0 0 0-5-9.17c-1.57-2.43-1.92-12.12-2-23.18l-3.7-3.7c.39 5.97.61 10.75.61 10.75Z"
        opacity=".1"
      />
      <path
        fill="#c4c8e2"
        d="M480.12 583.55c-.47-2.23 3.21 1.13 4.39 3.08l.06.11c1.7 3 .85 2.12 3.4 8.91s-.85 56 3 62a95.5 95.5 0 0 1 6.79 13.16l-6.79-2.55s-2.55-7.64-4.24-9.34-.85-17.4-.85-17.4-1.27-27.16-2.55-33.1c-1.01-4.78-2.21-20.18-3.21-24.87Z"
      />
      <path
        d="M480.12 583.55c-.47-2.23 3.21 1.13 4.39 3.08l.06.11c1.7 3 .85 2.12 3.4 8.91s-.85 56 3 62a95.5 95.5 0 0 1 6.79 13.16l-6.79-2.55s-2.55-7.64-4.24-9.34-.85-17.4-.85-17.4-1.27-27.16-2.55-33.1c-1.01-4.78-2.21-20.18-3.21-24.87Z"
        opacity=".1"
      />
      <path
        fill="#5f5d7e"
        d="M470.17 689.4s67.05-2.55 75.54 15.28-11.88 28.87-11.88 28.87-28.43-8.91-34.8-7.64-28.86-3.4-28.86-3.4Z"
      />
      <path
        fill="#fdc2cc"
        d="M507.52 678.37s15.28-8.49 23.77-3 7.64 20.37-4.24 20.79-18.25-6.79-18.25-6.79Z"
      />
      <path
        fill="#c4c8e2"
        d="M459.56 577.79s-13.58-9.34-33.53 25-14.85 93.36-14.85 93.36 14.85.85 14.43 14.85 57.72 17 57.72 17-.85-139.2-6.79-143-16.98-7.21-16.98-7.21Z"
      />
      <path
        d="M459.5 603.11a18.59 18.59 0 0 1 7.72 15.12c.07 12.71.38 40.1 1.67 45.28 1.7 6.79 27.16 15.7 40.32 15.28 0 0 6.37 11.88-1.27 14.43s-54.32 0-54.32 0-18.25-20.79-18.67-39-3-38.62 0-42.44c2.45-3.23 12.7-17.03 24.55-8.67Z"
        opacity=".1"
      />
      <path
        fill="#c4c8e2"
        d="M460.35 601.41a18.59 18.59 0 0 1 7.77 15.14c.07 12.71.38 40.1 1.67 45.28 1.7 6.79 27.16 15.7 40.32 15.28 0 0 6.37 11.88-1.27 14.43s-54.32 0-54.32 0-18.25-20.79-18.67-39-3-38.62 0-42.44c2.4-3.21 12.65-17.05 24.5-8.69Z"
      />
      <path
        fill="#4c4c56"
        d="M548.07 734.06c6.09 3 13.15 9.18-.23 18.57 0 0-38.19 4.24-50.5-3.4s0-19.95 0-19.95 28.17 7.49 41.69 3.83a12.83 12.83 0 0 1 9.04.95Z"
      />
      <path
        d="M548.07 734.92c6.09 2.9 13.15 8.78-.23 17.77 0 0-38.19 4.06-50.5-3.25s0-19.08 0-19.08 28.17 7.16 41.69 3.67a13.36 13.36 0 0 1 9.04.89ZM461.47 633.17s2.12 26.74 16.13 32.68M482.48 565.06a54.46 54.46 0 0 0-2.51 11.34c-2.68.9-6.6-1.36-9.59-1.36-7.78 0-10-8.7-14.85-14 0-2.36-4 3.15-4.46.21Z"
        opacity=".1"
      />
      <path
        fill="#fdc2cc"
        d="M445.12 548.08a26.86 26.86 0 0 0 .81 6.58l.09.34a26.87 26.87 0 1 0-.9-6.91Z"
      />
      <path
        d="M426.04 603.25a101 101 0 0 1 9.49-13.88 108 108 0 0 0-7.79 11.76c-19.95 34.38-14.85 93.36-14.85 93.36s14.85.85 14.43 14.85c-.38 12.57 46.41 16.25 56 16.87v2.22s-58.14-3-57.72-17-14.43-14.85-14.43-14.85-5.05-58.95 14.87-93.33ZM448.51 517.55c2.8-1.69 6.34-2.68 9.36-1.57a38.38 38.38 0 0 1 7-.64 11.25 11.25 0 0 0 3.82-.37 20.53 20.53 0 0 0 2.66-1.41c3.95-2.1 8.33-1.31 12.5-.77 3.39.44 9.12-.79 11.08 3.09a2.75 2.75 0 0 1-.45 3.3c-.38.28-.87.39-1.16.79a2.37 2.37 0 0 0-.13 1.82l2.8 14a10.52 10.52 0 0 1-8.33.38 23.44 23.44 0 0 1-2.2-1c-2.09 1.36-4.25.33-6.78.87a7.73 7.73 0 0 0-3.82 2.5 22.24 22.24 0 0 0-5.67 11.29c-.28 1.59-.65 3.55-2.19 4a10.05 10.05 0 0 1-1.77.18c-2.78.36-3.92 3.65-5.71 5.82-2.18 2.64-6.09 4.36-6.33 7.78-.06.88.13 1.85-.36 2.59a2.18 2.18 0 0 1-1.36.84 4.59 4.59 0 0 1-4.46-2 13.49 13.49 0 0 1-1.89-4.79l-5.17-20.5a41.31 41.31 0 0 1-1.35-7 21.1 21.1 0 0 1 9.91-19.2Z"
        opacity=".1"
      />
      <path
        fill="#865a61"
        d="M448.12 517.17c2.8-1.69 6.34-2.68 9.36-1.57a38.38 38.38 0 0 1 7-.64 11.25 11.25 0 0 0 3.82-.37 20.53 20.53 0 0 0 2.66-1.41c3.95-2.1 8.33-1.31 12.5-.77 3.39.44 9.12-.79 11.08 3.09a2.75 2.75 0 0 1-.45 3.3c-.38.28-.87.39-1.16.79a2.37 2.37 0 0 0-.13 1.82l2.8 14a10.52 10.52 0 0 1-8.33.38 23.44 23.44 0 0 1-2.2-1c-2.09 1.36-4.25.33-6.78.87a7.73 7.73 0 0 0-3.82 2.5 22.24 22.24 0 0 0-5.67 11.29c-.28 1.59-.65 3.55-2.19 4a10.05 10.05 0 0 1-1.77.18c-2.78.36-3.92 3.65-5.71 5.82-2.18 2.64-6.09 4.36-6.33 7.78-.06.88.13 1.85-.36 2.59a2.18 2.18 0 0 1-1.36.84 4.59 4.59 0 0 1-4.46-2 13.49 13.49 0 0 1-1.89-4.79l-5.17-20.5a41.31 41.31 0 0 1-1.35-7 21.1 21.1 0 0 1 9.91-19.2Z"
      />
    </svg>
  );
})();

export default FriendsSvg;
