import React from "react";
import { Link as RouterLink } from "react-router-dom";

import GiftsSvg from "@/svg/GiftsSvg";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  Typography
} from "@mui/material";
import PageHeader from "../components/PageHeader";

const Home = () => {
  return (
    <>
      <PageHeader title="Home" />
      <Grid container sx={{ mt: 4 }}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Card>
            <CardMedia title="gifts" sx={{ pt: 3 }}>
              <GiftsSvg />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h3">
                New feature: Wishlists!
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We are introducing our latest TribePost feature, wishlists! You can now create and
                share wishlists with fellow TribePost members.
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={RouterLink} to="/my_wishlists">
                Check it out!
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
