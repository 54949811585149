import { Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type PageHeaderProps = {
  title: string;
  subtitle?: string | null;
};

const PageHeader = ({ title, subtitle, children }: PropsWithChildren<PageHeaderProps>) => {
  return (
    <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "end" }}>
      <Stack flexGrow={1} spacing={0.5}>
        <Typography variant="h2">{title}</Typography>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </Stack>
      {children && (
        <Stack direction="row" spacing={1} pt={1}>
          {children}
        </Stack>
      )}
    </Stack>
  );
};

export default PageHeader;
