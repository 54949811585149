import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import type { FormEvent } from "react";
import React, { useEffect, useState } from "react";

import { useGetSharesQuery, useUpdateSharesMutation } from "@/hooks/useShares";
import { useIndexTribesQuery } from "@/hooks/useTribes";
import type { ShareRecord } from "@/types/shares";
import type { TribeRecord } from "@/types/tribes";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import BlankSlate from "./BlankSlate";
import Chooser from "./Chooser";

const getStatus = (shares?: ShareRecord[]) => ((shares || []).length > 0 ? "shared" : "private");
const getTribeIds = (shares?: ShareRecord[]) => (shares || []).map((share) => share.tribe_id);

export type ShareModalProps = {
  category: WishlistCategory;
  wishlist: WishlistRecord;
  open: boolean;
  onClose: () => void;
};

export type ShareStatus = "private" | "shared";

export type StatusChangeHandler = (
  event: React.MouseEvent<HTMLElement>,
  newShared: ShareStatus
) => void;

export type TribeIdsChangeHandler = (
  event: React.MouseEvent<HTMLElement>,
  newShares: TribeRecord["id"][]
) => void;

const ShareModal = ({ wishlist, open, onClose }: ShareModalProps) => {
  const { data: tribes, isLoading: tribesIsLoading } = useQuery(useIndexTribesQuery());
  const { data: shares, isLoading: sharesIsLoading } = useQuery(useGetSharesQuery(wishlist.id));
  const [status, setStatus] = useState<ShareStatus>();
  const [tribeIds, setTribeIds] = useState<ShareRecord["tribe_id"][]>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isLoading = tribesIsLoading || sharesIsLoading;

  const shareMutation = useUpdateSharesMutation(wishlist.id);

  const resetModal = () => {
    setStatus(getStatus(shares));
    setTribeIds(getTribeIds(shares));
    setErrorMessage(undefined);
    setIsSubmitting(false);
  };

  useEffect(() => {
    resetModal();
  }, [shares]);

  const handleStatusChange: StatusChangeHandler = (_event, newShared) => {
    setErrorMessage(undefined);
    setStatus(newShared);
  };

  const handleTribeIdsChange: TribeIdsChangeHandler = (_event, newShares) => {
    setErrorMessage(undefined);
    setTribeIds(newShares);
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    const tribe_ids = status === "private" ? [] : tribeIds || [];

    if (status === "shared" && tribe_ids.length === 0) {
      setErrorMessage("Please select at least one tribe to share with.");
      setIsSubmitting(false);
    } else {
      shareMutation.mutate(
        { tribe_ids },
        {
          onSuccess: () => {
            handleClose();
          }
        }
        // TODO: Handle error
      );
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: "24rem" } }}>
      <form onSubmit={handleSave}>
        <DialogTitle>Share Wishlist</DialogTitle>
        <DialogContent>
          {isLoading && <CircularProgress />}
          {!isLoading && tribes && status ? (
            <Chooser
              tribes={tribes}
              status={status}
              onStatusChange={handleStatusChange}
              tribeIds={tribeIds || []}
              onTribeIdsChange={handleTribeIdsChange}
              errorMessage={errorMessage}
            />
          ) : (
            <BlankSlate />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ShareModal;
