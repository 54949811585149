import { IdeaRecord } from "@/types/ideas";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";

type OpenLinkButtonProps = {
  idea: IdeaRecord;
};

const OpenLinkButton = ({ idea }: OpenLinkButtonProps) => {
  const confirm = useConfirm();

  const handleOpenLink = () => {
    if (!idea.url) return;

    confirm({
      title: "Open external link?",
      description: idea.url,
      confirmationButtonProps: {
        autoFocus: true,
        href: idea.url,
        //@ts-expect-error mui supports button link and target, but material-ui-confirm 3.0.11 has incorrect type for links
        target: "_blank"
      },
      contentProps: {
        sx: {
          overflowWrap: "break-word",
          wordWrap: "break-word",
          wordBreak: "break-word"
        }
      }
    })
      .then(() => {})
      .catch(() => {});
  };

  return (
    <Button onClick={handleOpenLink} startIcon={<OpenInNewIcon />}>
      Open Link
    </Button>
  );
};

export default OpenLinkButton;
