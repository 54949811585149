const localStorageWithExpiry = {
  getItem: <Type = string>(key: string) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    try {
      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value as Type;
    } catch {
      localStorage.removeItem(key);
      return null;
    }
  },
  setItem: <Type = string>(key: string, value: Type, expiry: number) => {
    const item = { value, expiry };
    localStorage.setItem(key, JSON.stringify(item));
  },
  updateItem: <Type = string>(key: string, value: Type) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return;

    try {
      const item = JSON.parse(itemStr);
      item.value = value;
      localStorage.setItem(key, JSON.stringify(item));
    } catch {
      return;
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  }
};

export const getExpiry = (ttl: number) => {
  const now = new Date();
  return now.getTime() + ttl;
};

export default localStorageWithExpiry;
