import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import FeelingBlueSvg from "@/svg/FeelingBlueSvg";

type PageNotFoundProps = {
  title?: string;
  titleVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  linkText?: string;
  linkTo?: string;
};

const PageNotFound = ({
  title = "Page not found",
  titleVariant = "h3",
  linkText,
  linkTo
}: PageNotFoundProps) => {
  return (
    <Stack alignItems="center">
      <Stack spacing={4}>
        <FeelingBlueSvg sx={{ width: "15rem" }} />
        <Stack textAlign="center" spacing={2}>
          <Typography variant={titleVariant}>{title}</Typography>
          {linkText && linkTo && (
            <Link component={RouterLink} to={linkTo} underline="hover">
              {linkText}
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PageNotFound;
