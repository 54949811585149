import { createContext } from "react";

import type { AuthType } from "@/hooks/useAuth";

export type AppContextType = {
  auth?: AuthType;
  isLoggedIn: () => boolean;
  updateAuth: (auth: AuthType) => void;
  clearAuth: () => void;
  updateCurrentUser: (currentUser: AuthType["currentUser"]) => void;
  isMobile: boolean;
  darkMode: boolean;
};

const emptyAppContext = (): AppContextType => {
  return {
    auth: undefined,
    isLoggedIn: () => false,
    updateAuth: (_auth) => {},
    clearAuth: () => {},
    updateCurrentUser: (_currentUser) => {},
    isMobile: false,
    darkMode: false
  };
};

const AppContext = createContext({
  ...emptyAppContext()
});

export { emptyAppContext };
export default AppContext;
