import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  Chip,
  List as MuiList,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import WishlistForm from "@/components/WishlistForm";
import useToggle from "@/hooks/useToggle";
import { getWishlistsPath } from "@/hooks/useWishlists";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

type ListProps = {
  category: WishlistCategory;
  wishlists: WishlistRecord[];
};

const List = ({ category, wishlists }: ListProps) => {
  const { open, handleOpen, handleClose } = useToggle();
  const { pathname } = useLocation();

  const isSelected = (wishlist: WishlistRecord) => {
    return pathname === getWishlistsPath(category, wishlist.id);
  };

  return (
    <>
      <MuiList>
        {wishlists.map((wishlist) => {
          return (
            <MuiListItemButton
              key={wishlist.id}
              selected={isSelected(wishlist)}
              component={RouterLink}
              to={getWishlistsPath(category, wishlist.id)}
            >
              <MuiListItemText>
                <Stack direction="row" spacing={2}>
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {wishlist.name}
                  </Typography>
                  {category === "shared" && (
                    <Chip label={`@${wishlist.username}`} variant="outlined" size="small" />
                  )}
                </Stack>
                {wishlist.description && (
                  <Typography variant="body2" color="text.secondary">
                    {wishlist.description}
                  </Typography>
                )}
              </MuiListItemText>
            </MuiListItemButton>
          );
        })}
      </MuiList>
      {category === "my" && (
        <>
          <Button size="large" startIcon={<AddIcon />} onClick={handleOpen} sx={{ width: "100%" }}>
            Create Wishlist
          </Button>
          {open && <WishlistForm category={category} open={open} onClose={handleClose} />}
        </>
      )}
    </>
  );
};

export default List;
