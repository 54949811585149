import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import type { ApiError } from "@/hooks/useRestApi";
import {
  getErrorMessages,
  useCreateWishlistMutation,
  useUpdateWishlistMutation
} from "@/hooks/useWishlists";
import type { WishlistCategory, WishlistRecord, WishlistRecordPartial } from "@/types/wishlists";

type WishlistFormProps = {
  category: WishlistCategory;
  open: boolean;
  onClose: () => void;
  wishlist?: WishlistRecord;
};

type WishlistFormData = {
  name: WishlistRecordPartial["name"];
  description: WishlistRecordPartial["description"];
};

const WishlistForm = ({ category, open, onClose, wishlist }: WishlistFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<WishlistFormData>({
    defaultValues: {
      name: wishlist?.name || "",
      description: wishlist?.description || ""
    }
  });
  const nameRef = useRef<HTMLInputElement>();

  const isCreate = typeof wishlist === "undefined";
  const createMutation = useCreateWishlistMutation(category);
  const updateMutation = useUpdateWishlistMutation(category);
  const verb = isCreate ? "Create" : "Update";

  useEffect(() => {
    setTimeout(() => {
      nameRef.current?.focus();
    }, 100);
  }, []);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleError = (error: ApiError) => {
    const messages = getErrorMessages(error);
    if (messages?.name) setError("name", { message: messages.name });
    if (messages?.description) setError("description", { message: messages.description });
    reset(undefined, { keepValues: true, keepErrors: true });
  };

  const onSubmit: SubmitHandler<WishlistFormData> = (data) => {
    if (isCreate) {
      return createMutation.mutate(data, {
        onSuccess: () => {
          handleClose();
        },
        onError: handleError
      });
    } else {
      return updateMutation.mutate(
        { wishlistId: wishlist.id, data },
        {
          onSuccess: () => {
            handleClose();
          },
          onError: handleError
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{verb} Wishlist</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            type="text"
            margin="normal"
            autoComplete="off"
            required
            fullWidth
            inputRef={nameRef}
            slotProps={{ input: { inputProps: { "data-1p-ignore": true } } }}
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />
          <TextField
            label="Description"
            type="text"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline
            {...register("description")}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
          />
          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            {verb}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WishlistForm;
