const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringInitials = (name: string) => {
  const [first, second] = name.split(" ", 2);
  return ((first || "")[0] || "") + ((second || "")[0] || "");
};

const getStringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: stringInitials(name)
  };
};

export default getStringAvatar;
