import { Undo as UndoIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";

import { useUndoPurchaseIdeaMutation } from "@/hooks/useIdeas";
import { IdeaRecord } from "@/types/ideas";
import { WishlistCategory, WishlistRecord } from "@/types/wishlists";

type UndoPurchaseButtonProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
  idea: IdeaRecord;
  sx?: SxProps<Theme>;
};

const UndoPurchaseButton = ({ category, wishlistId, idea, sx }: UndoPurchaseButtonProps) => {
  const confirm = useConfirm();
  const undoPurchase = useUndoPurchaseIdeaMutation(category, wishlistId);

  const handleUndoPurchase = () => {
    confirm({
      title: "Undo purchase?",
      description: "Do you want to cancel your purchase?",
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        undoPurchase.mutate({ ideaId: idea.id });
        // TODO: Handle error
      })
      .catch(() => {});
  };

  return (
    <Button onClick={handleUndoPurchase} startIcon={<UndoIcon />} sx={sx} size="small">
      Undo purchase
    </Button>
  );
};

export default UndoPurchaseButton;
