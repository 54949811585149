import { styled } from "@mui/material/styles";
import React from "react";

import type { SvgProps } from "@/types/utils";

const LightbulbMomentSvg = styled((props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 657.036 776.139"
      {...props}
    >
      <path
        fill="#f0f0f0"
        d="M645.501 760.668a50.794 50.794 0 0 1-13.59 12.63c-1.12.71-2.27 1.38-3.44 2h-27.53c.33-.66.65-1.33.96-2a95.354 95.354 0 0 0-19.84-109.34c16.64 5.14 32.02 15.16 42.08 29.37a64.47 64.47 0 0 1 10.23 23 96.276 96.276 0 0 0-7.67-48.41c13.51 10.99 24.03 26.04 28.04 42.98 4.01 16.95 1.1 35.76-9.24 49.77Z"
      />
      <path fill="#3f3d56" d="M656.654 776.139H398.916v-2.106h258.12Z" data-name="Path 2533" />
      <path
        fill="#6c63ff"
        d="M308.748 263.27c0 38.64-13.5 71.63-39.19 96.18-18.23 17.43-46.31 41.86-46.31 67.69v21.37h-85.5v-21.37c0-26.1-28.19-50.58-46.31-67.69-25.11-23.69-39.19-58.92-39.19-96.18v-.79a128.25 128.25 0 0 1 256.5.79Z"
      />
      <path
        fill="#3f3d56"
        d="M223.247 451.51a3 3 0 0 1-3-3v-21.374c0-25.125 24.779-48.58 42.873-65.706 1.5-1.422 2.961-2.8 4.363-4.145 25.029-23.928 38.259-56.437 38.259-94.014 0-70.229-55.013-125.243-125.242-125.243h-.354A125.03 125.03 0 0 0 55.257 262.566v.7c0 36.8 13.939 71.063 38.245 94.007 1.231 1.163 2.51 2.36 3.823 3.589 18.327 17.159 43.427 40.658 43.427 66.275v21.373a3 3 0 0 1-6 0v-21.374c0-23.015-24-45.486-41.528-61.9q-1.979-1.851-3.841-3.605c-25.5-24.072-40.126-59.924-40.126-98.364v-.72c.2-72.045 58.885-130.523 130.88-130.523h.371A130.557 130.557 0 0 1 311.742 261.9q.004.686 0 1.371c0 39.255-13.871 73.264-40.112 98.351a1208.608 1208.608 0 0 1-4.386 4.165c-17.3 16.377-41 38.806-41 61.349v21.374a3 3 0 0 1-3 3Z"
        data-name="Path 2546"
      />
      <path
        fill="#6c63ff"
        d="M208.999 490.005h-57a5 5 0 1 1 0-10h57a5 5 0 0 1 0 10Z"
        data-name="Path 2547"
      />
      <path
        fill="#3f3d56"
        d="M186.628 523.505H174.36a23.447 23.447 0 0 1-12.094-3.35 2.794 2.794 0 0 1-1.269-3.215 2.886 2.886 0 0 1 2.802-2.142h33.389a2.887 2.887 0 0 1 2.802 2.142 2.793 2.793 0 0 1-1.269 3.214 23.445 23.445 0 0 1-12.094 3.35ZM209.009 505.799l-57.338-.007-.178-.38a4.726 4.726 0 0 1 .9-4.059 4.856 4.856 0 0 1 3.823-1.848h48.557a4.855 4.855 0 0 1 3.824 1.849 4.698 4.698 0 0 1 .91 4.007l-.09.387Z"
      />
      <path
        fill="#e4e4e4"
        d="M47.899 145.411a4.98 4.98 0 0 1-3.286-1.233L1.674 106.697a5 5 0 0 1 6.576-7.533l42.939 37.481a5 5 0 0 1-3.29 8.767Z"
        data-name="Path 2548"
      />
      <path
        fill="#e4e4e4"
        d="M305.977 145.411a5 5 0 0 1-3.29-8.767l42.938-37.484a5 5 0 0 1 6.576 7.533l-42.939 37.481a4.98 4.98 0 0 1-3.285 1.237Z"
        data-name="Path 2549"
      />
      <path
        fill="#6c63ff"
        d="M223.247 472.26h-85.5a5 5 0 0 1 0-10h85.5a5 5 0 0 1 0 10Z"
        data-name="Path 2550"
      />
      <path
        fill="#e6e6e6"
        d="m178.398 341.64-17.78 106.87h-10.15L168.528 340a5.003 5.003 0 0 1 9.87 1.64ZM212.568 448.51h-10.15l-17.78-106.87a5.003 5.003 0 0 1 9.87-1.64Z"
      />
      <path
        fill="#e6e6e6"
        d="M148.002 338.07a5 5 0 0 1 5-5h54.993a5 5 0 0 1 0 10h-54.993a5 5 0 0 1-5-5Z"
        data-name="Path 2551"
      />
      <path
        fill="#e4e4e4"
        d="M180.499 95.5a5 5 0 0 1-5-5V5a5 5 0 0 1 10 0v85.5a5 5 0 0 1-5 5Z"
        data-name="Path 2553"
      />
      <path
        fill="#feb8b8"
        d="M589.219 762.149h-12.258l-5.832-47.288h18.092Z"
        data-name="Path 2559"
      />
      <path
        fill="#2f2e41"
        d="M592.346 774.033h-39.531v-.5a15.387 15.387 0 0 1 15.385-15.386h24.146Z"
        data-name="Path 2560"
      />
      <path
        fill="#feb8b8"
        d="m496.082 761.745-11.844-3.167 6.58-47.19 17.48 4.674Z"
        data-name="Path 2561"
      />
      <path
        fill="#2f2e41"
        d="m496.033 774.033-38.189-10.212.129-.483a15.387 15.387 0 0 1 18.839-10.89l23.325 6.237Z"
        data-name="Path 2562"
      />
      <path
        fill="#2f2e41"
        d="m596.272 497.137 10.269 11.931-13.28 242.442h-28.369l-14.3-186.551-44.349 191.554-29.492-7.233 26.816-243.3Z"
        data-name="Path 2563"
      />
      <path
        fill="#e6e6e6"
        d="m510.423 340.612 28.246-14.172 43.437.764 37.38 19.127-21.268 106.218 9.187 55.38a226.533 226.533 0 0 1-108.335.892l-.284-.068s21.114-74.916 12.126-97.779Z"
        data-name="Path 2564"
      />
      <path fill="#feb8b8" d="M592.139 284.004a31.994 31.994 0 1 0 0 .237Z" data-name="Path 2565" />
      <path
        fill="#feb8b8"
        d="M506.525 418.053a11.462 11.462 0 0 0 16.65 5.627l57.353 30.318 1.857-13.971-55.73-32.863a11.524 11.524 0 0 0-20.131 10.889Z"
        data-name="Path 2567"
      />
      <path
        fill="#feb8b8"
        d="M582.954 420.468a11.462 11.462 0 0 1-17.478 1.848l-62.6 17.035.545-17.738 62.271-16.173a11.524 11.524 0 0 1 17.261 15.03Z"
        data-name="Path 2568"
      />
      <path
        fill="#e6e6e6"
        d="m607.28 347.1 12.205-.765s14.29 18.855 6.364 39.316c0 0 1.373 73.5-30.276 70.48s-41.65-3.019-41.65-3.019l9.5-26.5 21.253-6.562s-6.55-28.894 5.849-40.916Z"
        data-name="Path 2569"
      />
      <path
        fill="#e6e6e6"
        d="m519.148 346.455-1.725-8.843s-25.44-.6-30.47 37.951c0 0-22.877 57.692-.454 65.121s47.089 0 47.089 0l-1.857-25.444-24.67-5.033s12.745-16.489 5.805-30.792Z"
        data-name="Path 2570"
      />
      <path
        fill="#2f2e41"
        d="M571.222 299.715c-2.352-.924-4.959-.876-7.445-1.331-8.894-1.639-15.753-10.707-14.908-19.71a10.848 10.848 0 0 0 .058-3.533c-.617-2.569-3.472-3.948-6.095-4.256s-5.334.086-7.885-.597c-3.94-1.055-6.883-4.648-7.88-8.603a19.487 19.487 0 0 1 1.266-11.963l1.224 2.552a9.918 9.918 0 0 0 2.506-4.852 6.255 6.255 0 0 1 5.167 3.272c1.58.706 1.388-2.906 2.964-3.62a2.959 2.959 0 0 1 2.1.272c3.473 1.166 7.156-.583 10.597-1.835a40.417 40.417 0 0 1 18.642-2.13c4.183.51 8.405 1.746 11.66 4.419a26.607 26.607 0 0 1 6.156 8.274c4.017 7.508 7.043 15.792 6.96 24.31a35.298 35.298 0 0 1-6.164 19.519c-2.052 2.986-8.68 13.343-13.084 12.203-5.534-1.43.786-9.793-5.839-12.391Z"
        data-name="Path 2387"
      />
    </svg>
  );
})();

export default LightbulbMomentSvg;
