import { Stack, Typography } from "@mui/material";
import React from "react";

import GiftsSvg from "@/svg/GiftsSvg";
import type { WishlistCategory } from "@/types/wishlists";

import CreateAction from "./CreateAction";

type BlankSlateProps = {
  category: WishlistCategory;
  hasWishlists: boolean;
};

const BlankSlate = ({ category, hasWishlists }: BlankSlateProps) => {
  const title =
    category === "my"
      ? hasWishlists
        ? "No wishlist selected"
        : "No wishlists created"
      : hasWishlists
        ? "No wishlist selected"
        : "No wishlists shared with you";

  const subtitle =
    category === "my"
      ? hasWishlists
        ? "Create or select a wishlist to get started."
        : "Create a wishlist to get started."
      : hasWishlists
        ? "Select a wishlist to get started."
        : "FolAsk a friend to share a wishlist with you and return here.";

  return (
    <Stack alignItems="center" spacing={4} mt={10}>
      <GiftsSvg sx={{ width: "20rem" }} />
      <Stack textAlign="center" spacing={1}>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Stack>
      {category === "my" && <CreateAction category={category} />}
    </Stack>
  );
};

export default BlankSlate;
