import { AppBar, Container, Toolbar } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

export type NavbarLinkType = {
  label: string;
  to: string;
  selected: boolean;
  restricted?: boolean;
};

const Navbar = () => {
  const { pathname } = useLocation();
  const links: NavbarLinkType[] = [
    { label: "Home", to: "/", selected: pathname === "/" },
    {
      label: "Wishlists",
      to: "/my_wishlists",
      selected: pathname.startsWith("/my_wishlists") || pathname.startsWith("/shared_wishlists"),
      restricted: true
    }
  ];

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.4)" : "rgba(9, 9, 11, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider"
            })}
          >
            <DesktopNavbar links={links} />
            <MobileNavbar links={links} />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
