import { Box, CssBaseline, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import type { AuthType } from "@/hooks/useAuth";
import localStorageWithExpiry, { getExpiry } from "@/lib/localStorageWithExpiry";
import { darkThemeOptions, lightThemeOptions } from "@/lib/themes";
import AppContext from "@/store/app-context";

const TOKEN_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours

type TimeoutType = ReturnType<typeof setTimeout>;

const Root = () => {
  const [auth, setAuth] = useState<AuthType | undefined>(
    localStorageWithExpiry.getItem("auth") || undefined
  );
  const [_authTimeout, setAuthTimeout] = useState<TimeoutType | undefined>();
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return typeof auth !== "undefined";
  };

  const updateAuth = (auth: AuthType) => {
    const expiry = getExpiry(TOKEN_TIMEOUT);
    localStorageWithExpiry.setItem("auth", auth, expiry);
    setAuth(auth);
    setAuthTimeout((currentTimeout) => {
      if (typeof currentTimeout !== "undefined") clearTimeout(currentTimeout);

      return setTimeout(() => {
        clearAuth();
        navigate("/");
      }, TOKEN_TIMEOUT);
    });
  };

  const clearAuth = () => {
    localStorageWithExpiry.removeItem("auth");
    setAuth(undefined);
    setAuthTimeout((currentTimeout) => {
      if (typeof currentTimeout !== "undefined") clearTimeout(currentTimeout);

      return undefined;
    });
  };

  const updateCurrentUser = (currentUser: AuthType["currentUser"]) => {
    setAuth((currentAuth) => {
      if (typeof currentAuth === "undefined") return currentAuth;

      const { currentUser: _, ...rest } = currentAuth;
      const auth = { ...rest, currentUser };
      localStorageWithExpiry.updateItem("auth", auth);

      return auth;
    });
  };

  const lightTheme = createTheme(lightThemeOptions);
  const darkTheme = createTheme(darkThemeOptions);

  const darkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const isMobile = !useMediaQuery(lightTheme.breakpoints.up("md"));

  return (
    <AppContext.Provider
      value={{
        auth,
        isLoggedIn,
        updateAuth,
        clearAuth,
        updateCurrentUser,
        isMobile,
        darkMode
      }}
    >
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <ConfirmProvider>
          <Box
            sx={{
              backgroundImage: darkMode
                ? "linear-gradient(#1e1b4b, #09090b)"
                : "linear-gradient(#a5b4fc, #ffffff)",
              backgroundRepeat: "no-repeat",
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              height: "40%",
              zIndex: -1
            }}
          />
          <CssBaseline />
          <Outlet />
        </ConfirmProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default Root;
