import { Paper } from "@mui/material";
import React from "react";

import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import List from "./List";

type SidebarProps = {
  category: WishlistCategory;
  wishlists: WishlistRecord[];
};

const Sidebar = ({ category, wishlists }: SidebarProps) => {
  return (
    <Paper elevation={3} sx={{ width: "18rem", flex: "none" }}>
      <List category={category} wishlists={wishlists} />
    </Paper>
  );
};

export default Sidebar;
