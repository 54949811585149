import { AxiosError, AxiosResponse, isAxiosError } from "axios";

import type { ActiveRecord } from "@/types/rails";
import useRequest, { RequestConfig, RequestResponse } from "./useRequest";

export type ApiRequestConfig = Omit<RequestConfig, "data">;

export type RestApiReponse<Type extends ActiveRecord> = RequestResponse<Type>;

type RestApiIndex<Type extends ActiveRecord> = (
  config?: ApiRequestConfig
) => Promise<AxiosResponse<Type[]>>;
type RestApiShow<Type extends ActiveRecord> = (
  id: ActiveRecord["id"],
  config?: ApiRequestConfig
) => Promise<AxiosResponse<Type>>;
type RestApiCreate<Type extends ActiveRecord> = (
  data: object,
  config?: ApiRequestConfig
) => Promise<AxiosResponse<Type>>;
type RestApiUpdate<Type extends ActiveRecord> = (
  id: ActiveRecord["id"],
  data: object,
  config?: ApiRequestConfig
) => Promise<AxiosResponse<Type>>;
type RestApiDestroy = (id: ActiveRecord["id"], config?: ApiRequestConfig) => Promise<AxiosResponse>;

export type UseRestApiReturn<Type extends ActiveRecord> = {
  baseUrl: string;
  index: RestApiIndex<Type>;
  show: RestApiShow<Type>;
  create: RestApiCreate<Type>;
  update: RestApiUpdate<Type>;
  destroy: RestApiDestroy;
};

export type RestApiErrorMessages<Type> = {
  [Property in keyof Type]: string;
};

export type ApiError = Error | AxiosError;

export const getErrorMessages = <Type>(error: ApiError) => {
  if (isAxiosError(error) && error.response?.data?.errors) {
    const errorMessages = error.response.data.errors as RestApiErrorMessages<Type>;
    return errorMessages;
  } else {
    throw error;
  }
};

const useRestApi = <Type extends ActiveRecord>(baseUrl: string): UseRestApiReturn<Type> => {
  const request = useRequest();

  return {
    baseUrl,
    index: (config = {}) => {
      return request.get<Type[]>(baseUrl, config);
    },
    show: (id, config = {}) => {
      return request.get<Type>(`${baseUrl}/${id}`, config);
    },
    create: (data, config = {}) => {
      return request.post<Type>(baseUrl, { data, ...config });
    },
    update: (id, data, config = {}) => {
      return request.patch<Type>(`${baseUrl}/${id}`, { data, ...config });
    },
    destroy: (id, config = {}) => {
      return request.delete(`${baseUrl}/${id}`, config);
    }
  };
};

export default useRestApi;
