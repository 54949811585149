import { useMutation, useQueryClient } from "@tanstack/react-query";

import useRequest from "@/hooks/useRequest";
import hasQueryData from "@/lib/hasQueryData";
import type { ShareRecord } from "@/types/shares";
import { TribeRecord } from "@/types/tribes";
import type { WishlistRecord } from "@/types/wishlists";

export const getTribeShareId = (
  shares: ShareRecord[] | undefined,
  tribeId: ShareRecord["tribe_id"]
) => {
  const share = (shares || []).find((share) => share.tribe_id === tribeId);
  return share?.id;
};

export const getSharesQueryKey = (wishlistId: WishlistRecord["id"]) => {
  return ["shares", wishlistId.toString()];
};

export const getSharesRequestUrl = (wishlistId: WishlistRecord["id"]) => {
  return `/api/my_wishlists/${wishlistId}/shares`;
};

export const useGetSharesQuery = (wishlistId: WishlistRecord["id"]) => {
  const request = useRequest();
  return {
    queryKey: getSharesQueryKey(wishlistId),
    queryFn: async () => {
      const response = await request.get<ShareRecord[]>(getSharesRequestUrl(wishlistId));
      return response.data;
    }
  };
};

export const useOnSharesUpdate = (wishlistId: WishlistRecord["id"]) => {
  const queryClient = useQueryClient();

  return (newShares: ShareRecord[]) => {
    if (hasQueryData(queryClient, getSharesQueryKey(wishlistId))) {
      queryClient.setQueryData(getSharesQueryKey(wishlistId), newShares);
    }
  };
};

export const useUpdateSharesMutation = (wishlistId: WishlistRecord["id"]) => {
  const request = useRequest();
  const onSharesUpdate = useOnSharesUpdate(wishlistId);

  return useMutation({
    mutationFn: async (data: { tribe_ids: TribeRecord["id"][] }) => {
      const response = await request.patch<ShareRecord[]>(getSharesRequestUrl(wishlistId), {
        data
      });
      return response.data;
    },
    onSuccess: (newShares) => {
      onSharesUpdate(newShares);
    }
  });
};
