import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import type { SessionRecord } from "@/types/sessions";
import useAppContext from "./useAppContext";
import type { RequestResponse } from "./useRequest";
import useRequest, { isRequestError } from "./useRequest";

export type AuthResponse = RequestResponse<SessionRecord>;
export type AuthType = {
  sessionId: SessionRecord["id"];
  authToken: string;
  currentUser: SessionRecord["user"];
};

type SignInRequestProps = {
  email: string;
  password: string;
  onSuccess?: (session: SessionRecord) => void;
  onError?: (errorMessage: string) => void;
};

type SignOutRequestProps = {
  sessionId: SessionRecord["id"];
  onSuccess?: () => void;
};

const useAuth = () => {
  const { state: redirect } = useLocation();
  const navigate = useNavigate();
  const { updateAuth, clearAuth } = useAppContext();
  const request = useRequest();
  const queryClient = useQueryClient();

  const signInRequest = ({ email, password, onSuccess, onError }: SignInRequestProps) => {
    request
      .post<SessionRecord>("/api/sign_in", { data: { email, password } })
      .then((response) => {
        onSuccess?.(response.data);
        updateAuthAndRedirect(response);
      })
      .catch((error) => {
        if (isAuthError(error)) {
          onError?.(error.response.data.error);
        } else {
          throw error;
        }
      });
  };

  const signOutRequest = ({ sessionId, onSuccess }: SignOutRequestProps) => {
    request.delete(`/api/sessions/${sessionId}`).then(() => {
      onSuccess?.();
      clearAuthAndRedirect();
    });
  };

  const isAuthError = (error: unknown) => {
    return isRequestError(error) && typeof error.response?.data?.error !== "undefined";
  };

  const updateAuthAndRedirect = (response: AuthResponse) => {
    // Put together auth object
    const { id: sessionId, user: currentUser } = response.data;
    const authToken = response.headers["x-session-token"];
    const auth = { sessionId, authToken, currentUser };
    updateAuth(auth);

    // Redirect to previous path or home
    if (typeof redirect?.path !== "undefined") {
      navigate(redirect.path);
    } else {
      navigate("/");
    }
  };

  const clearAuthAndRedirect = () => {
    clearAuth();
    navigate("/");
    queryClient.clear();
  };

  return {
    signInRequest,
    signOutRequest
  };
};

export default useAuth;
