import { Box, Button, MenuItem, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import type { NavbarLinkType } from "@/components/Navbar";
import useAppContext from "@/hooks/useAppContext";
import LogoSvg from "@/svg/LogoSvg";

import ProfileDropdown from "./ProfileDropdown";

type DesktopNavbarProps = {
  links: NavbarLinkType[];
};

const DesktopNavbar = ({ links }: DesktopNavbarProps) => {
  const { isLoggedIn } = useAppContext();

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          px: 0
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          noWrap
          color="text.primary"
          sx={{
            alignItems: "center",
            display: "flex",
            fontWeight: 700,
            mr: 2
          }}
        >
          <LogoSvg sx={{ color: "primary.main", width: "1.7rem", mr: 1.5 }} />
          TRIBEPOST
        </Typography>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {links.map(({ label, restricted = false, ...props }) => {
            return restricted && !isLoggedIn() ? null : (
              <MenuItem
                key={label}
                component={RouterLink}
                sx={{ py: "6px", px: "12px" }}
                {...props}
              >
                <Typography variant="body2" color="text.primary">
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          gap: 0.5,
          alignItems: "center"
        }}
      >
        {isLoggedIn() && <ProfileDropdown />}
        {!isLoggedIn() && (
          <>
            <Button
              color="primary"
              variant="text"
              size="small"
              component={RouterLink}
              to="/sign_in"
            >
              Sign in
            </Button>
            {/* <Button
              color="primary"
              variant="contained"
              size="small"
              component={RouterLink}
              to="/sign_up"
            >
              Sign up
            </Button> */}
          </>
        )}
      </Box>
    </>
  );
};

export default DesktopNavbar;
