import { useQuery } from "@tanstack/react-query";
import React from "react";

import WishlistList from "@/components/WishlistList";
import { useIndexWishlistsQuery } from "@/hooks/useWishlists";

const SharedWishlists = () => {
  const { data: wishlists } = useQuery(useIndexWishlistsQuery("shared"));

  return <WishlistList category="shared" wishlists={wishlists || []} />;
};

export default SharedWishlists;
