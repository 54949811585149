import { Public as PublicIcon } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { getErrorMessages, useCreateIdeaMutation, useUpdateIdeaMutation } from "@/hooks/useIdeas";
import type { ApiError } from "@/hooks/useRestApi";
import type { IdeaRecord } from "@/types/ideas";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

type IdeaFormProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
  open: boolean;
  onClose: () => void;
  idea?: IdeaRecord;
};

type IdeaFormData = {
  name: IdeaRecord["name"];
  comment: IdeaRecord["comment"];
  url: IdeaRecord["url"];
  cost: IdeaRecord["cost"];
};

const IdeaForm = ({ category, wishlistId, open, onClose, idea }: IdeaFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<IdeaFormData>({
    defaultValues: {
      name: idea?.name || "",
      comment: idea?.comment || "",
      url: idea?.url || "",
      cost: idea?.cost === "0" ? undefined : idea?.cost
    }
  });
  const nameRef = useRef<HTMLInputElement>();

  const isCreate = typeof idea === "undefined";
  const createMutation = useCreateIdeaMutation(category, wishlistId);
  const updateMutation = useUpdateIdeaMutation(category, wishlistId);
  const verb = isCreate ? "Add" : "Update";

  useEffect(() => {
    setTimeout(() => {
      nameRef.current?.focus();
    }, 100);
  }, []);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleError = (error: ApiError) => {
    const messages = getErrorMessages(error);
    if (messages?.name) setError("name", { message: messages.name });
    if (messages?.comment) setError("comment", { message: messages.comment });
    if (messages?.url) setError("url", { message: messages.url });
    if (messages?.cost) setError("cost", { message: messages.cost });
    reset(undefined, { keepValues: true, keepErrors: true });
  };

  const onSubmit: SubmitHandler<IdeaFormData> = (data) => {
    if (isCreate) {
      return createMutation.mutate(data, {
        onSuccess: () => {
          handleClose();
        },
        onError: handleError
      });
    } else {
      return updateMutation.mutate(
        { ideaId: idea.id, data },
        {
          onSuccess: () => {
            handleClose();
          },
          onError: handleError
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{verb} Idea</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            type="text"
            margin="normal"
            autoComplete="off"
            required
            fullWidth
            inputRef={nameRef}
            slotProps={{ input: { inputProps: { "data-1p-ignore": true } } }}
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />
          <TextField
            label="Comment"
            type="text"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline
            {...register("comment")}
            error={!!errors.comment?.message}
            helperText={errors.comment?.message}
          />
          <TextField
            label="Link"
            type="text"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <PublicIcon />
                  </InputAdornment>
                )
              }
            }}
            {...register("url")}
            error={!!errors.url?.message}
            helperText={errors.url?.message}
          />
          <TextField
            label="Estimated Cost"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            margin="normal"
            autoComplete="off"
            fullWidth
            placeholder="0"
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }
            }}
            {...register("cost")}
            error={!!errors.cost?.message}
            helperText={errors.cost?.message}
          />
          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            {verb}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default IdeaForm;
