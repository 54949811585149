import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAppContext from "@/hooks/useAppContext";

const RequireAuth = () => {
  const { isLoggedIn } = useAppContext();
  const { pathname: path } = useLocation();

  return isLoggedIn() ? <Outlet /> : <Navigate to="/sign_in" state={{ path }} />;
};

export default RequireAuth;
