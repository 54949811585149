import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  IosShare as IosShareIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useId, useState } from "react";

import WishlistForm from "@/components/WishlistForm";
import useToggle from "@/hooks/useToggle";
import { useDeleteWishlistMutation } from "@/hooks/useWishlists";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import ShareModal from "./ShareModal";

type ManageDropdownProps = {
  category: WishlistCategory;
  wishlist: WishlistRecord;
};

const ManageDropdown = ({ category, wishlist }: ManageDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = useId();
  const confirm = useConfirm();

  const {
    open: shareModalOpen,
    handleOpen: handleShareModalOpen,
    handleClose: handleShareModalClose
  } = useToggle();

  const {
    open: editModalOpen,
    handleOpen: handleEditModalOpen,
    handleClose: handleEditModalClose
  } = useToggle();

  const handleShare = () => {
    handleClose();
    handleShareModalOpen();
  };

  const handleEdit = () => {
    handleClose();
    handleEditModalOpen();
  };

  const deleteMutation = useDeleteWishlistMutation(category, wishlist.id);

  const handleDelete = () => {
    handleClose();

    confirm({
      description: `This will permanently delete ${wishlist.name}`,
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        deleteMutation.mutate();
      })
      .catch(() => {});
  };

  return (
    <>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        variant="outlined"
        onClick={handleOpen}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ whiteSpace: "nowrap" }}
      >
        Manage Wishlist
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        MenuListProps={{ sx: { minWidth: "11.5rem" } }}
      >
        <Box>
          <MenuItem onClick={handleShare}>
            <ListItemIcon>
              <IosShareIcon />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Box>
      </Menu>
      {shareModalOpen && (
        <ShareModal
          category={category}
          open={shareModalOpen}
          onClose={handleShareModalClose}
          wishlist={wishlist}
        />
      )}
      {editModalOpen && (
        <WishlistForm
          category={category}
          wishlist={wishlist}
          open={editModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </>
  );
};

export default ManageDropdown;
