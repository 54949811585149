import useRestApi from "@/hooks/useRestApi";
import type { TribeRecord } from "@/types/tribes";

export const getTribesQueryKey = () => {
  return ["tribes"];
};

export const useTribesApi = () => {
  return useRestApi<TribeRecord>(`/api/tribes`);
};

export const useIndexTribesQuery = () => {
  const api = useTribesApi();
  return {
    queryKey: getTribesQueryKey(),
    queryFn: async () => {
      const response = await api.index();
      return response.data;
    }
  };
};
