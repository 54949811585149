import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon
} from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useId, useState } from "react";

import IdeaForm from "@/components/IdeaForm";
import { useDeleteIdeaMutation } from "@/hooks/useIdeas";
import useToggle from "@/hooks/useToggle";
import type { IdeaRecord } from "@/types/ideas";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

type ManageDropdownProps = {
  idea: IdeaRecord;
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
};

const ManageDropdown = ({ idea, category, wishlistId }: ManageDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = useId();
  const confirm = useConfirm();

  const {
    open: modalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useToggle();

  const handleEdit = () => {
    handleClose();
    handleModalOpen();
  };

  const deleteMutation = useDeleteIdeaMutation(category, wishlistId, idea.id);

  const handleDelete = () => {
    handleClose();

    confirm({
      description: `This will permanently delete ${idea.name}`,
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        deleteMutation.mutate();
      })
      .catch(() => {});
  };

  return (
    <>
      <IconButton
        aria-label="manage idea"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {modalOpen && (
        <IdeaForm
          category={category}
          wishlistId={wishlistId}
          idea={idea}
          open={modalOpen}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default ManageDropdown;
