import { AccountCircleOutlined as AccountCircleOutlinedIcon } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Container, LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import useAppContext from "@/hooks/useAppContext";
import useRequest from "@/hooks/useRequest";
import type { UserRecord } from "@/types/users";

type AccountEmailFormData = object;

const AccountEmailVerification = () => {
  const navigate = useNavigate();
  const request = useRequest();
  const { auth, updateCurrentUser } = useAppContext();
  const [alert, setAlert] = useState();
  const [searchParams] = useSearchParams();
  const sid = searchParams.get("sid");
  const {
    formState: { isSubmitting },
    reset,
    handleSubmit
  } = useForm<AccountEmailFormData>();

  const onSubmit: SubmitHandler<AccountEmailFormData> = () =>
    request
      .get<UserRecord>("/api/account/email_verification", {
        params: { sid }
      })
      .then((response) => {
        if (typeof auth !== "undefined") {
          updateCurrentUser(response.data);
          navigate("/");
        } else {
          navigate("/sign_in");
        }
        reset();
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          setAlert(error.response.data.error);
        } else {
          throw error;
        }
        reset();
      });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleOutlinedIcon />
        </Avatar>
        <Typography variant="h1">Verify email</Typography>
        {alert && <Alert severity="error">{alert}</Alert>}
        <Box component="form" sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          {isSubmitting && <LinearProgress />}
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting || alert}
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Verify my email address
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountEmailVerification;
